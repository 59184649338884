import React, { useEffect, useState } from "react";
import m from "../../modal.module.scss";
import st from "../../style.module.scss";
import { useLocation } from "react-router-dom";
import {
  MdClose,
} from "react-icons/md";
import { useForm } from "react-hook-form";
import { Modal, Col, Row, Form, Button } from "react-bootstrap";
import { httpRequest } from "../../Apis/commonApis";

const AddTag = (props: any) => {
  let { show, handleClose, getServiceTag, data } = props;
  const [error, setError] = useState("");


  
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm();



  // useEffect(()=>{
  //   getServiceTag()
  // },[])

  useEffect(() => {
    if (data) {
      setValue("name", data?.name);
    }
  }, [data, setValue]);
  

  const addServiceTag = async (postData: any) => {
    let finalData = {
      name: postData?.name,
    };

    let res;
    if (data?.id) {
      res = await httpRequest(
        `attribute3/${data?.id}`,
        "PATCH",
        finalData,
        "json"
      );
    } else {
      res = await httpRequest(`attribute3`, "post", finalData, "json");
    }
    
    if (res?.status) {
      reset();
      getServiceTag();
      handleClose();
    } else {
      setError(res.message);
    }
  };



  return (
    <>
      <Modal
        size="sm"
        centered
        show={show}
        onHide={handleClose}
        className={`${m.modalCts}`}
      >
        <Modal.Body>
          <button
            className={`${m.closeIcon}`}
            title="Close"
            onClick={handleClose}
          >
            <MdClose />
          </button>
          <Col lg={12} className={`${m.title}`}>
            <h5>{data?.id ? "Edit attribute3" : "Add attribute3"}</h5>
          </Col>
          <Row>
            <Col md={12}>
              <Row className="w-100 mx-auto">
                <Col lg={12}>
                  <Form.Group className={`${st.formField}`}>
                    <Form.Label>
                      attribute3 <span className="text-danger">*</span>
                    </Form.Label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("name", {
                        required: true,
                      })}
                    />
                    {error && <p className="errorMessage">{error}</p>}
                  </Form.Group>
                </Col>
                <Col lg={12} className="mt-2">
                  <Button
                    className={`${st.btnDisabled}`}
                    onClick={handleSubmit(addServiceTag)}
                  >
                    {data?.id ? "Update" : "Save"}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddTag;
