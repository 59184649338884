import React, { useEffect, useState } from "react";
import st from "../../style.module.scss";
import { httpRequest } from "../../Apis/commonApis";
import { ActiveDeactive } from "../Modals/ActiveDeactive";


const Status = (props:any) => {
  const [showActiveDeactive, setActiveDeactiveShow] = useState(false);
  const handleActiveDeactiveClose = () => setActiveDeactiveShow(false);
  const handleActiveDeactiveShow = () => setActiveDeactiveShow(true);

  const [status, setStatus] = useState<any>(null)
  
  useEffect(() =>{
    setStatus(props.status)
  },[props.status])


  const updateStatus = async (activeStatus: any) => {
    let statusVal = activeStatus
    if (props.api) {
      let body: any;
      if (props.apiMethod === 'formdata') {
        body = new FormData();
        body.append("isActive", statusVal);
      } else {
        body = {
          [props?.type ? props?.type : "isActive"]: statusVal,
        };
      }
      const res = await httpRequest(`${props.api}/${props.id}`,"PATCH",body,props.apiMethod);
      if (res?.status) {
        props.getLatest();
      }
    }
  };

  const handleActiveWithPopup = () =>{
    updateStatus(status)
    handleActiveDeactiveClose()
  } 
  return (
    <>
        <label className={`${st.switch}`} onClick={() => props?.disabled ? null : handleActiveDeactiveShow()}>
          <input className={`${st.switchInput}`} type="checkbox" disabled={props?.disabled ? props?.disabled : false }  onClick={(e:any) => {
            setStatus(e.target.checked)
            handleActiveDeactiveShow()}}  checked={props.status}/> 
          <span className={`${st.switchLabel}`} data-on={`${`${props?.defaultName ? `${props?.defaultName} Act` : ""}` || "Active"}`} data-off={`${props?.defaultName ?  `${`${props?.defaultName} Deact`}` : "" || "Deactive"}`}></span> 
          <span className={`${st.switchHandle}`}></span> 
        </label>

        <ActiveDeactive item={props?.item || ''} show={showActiveDeactive} handleActiveDeactiveClose={handleActiveDeactiveClose} handleActiveWithPopup={handleActiveWithPopup}  status={status}/>
    </>
  );
};

export default Status;
