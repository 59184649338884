import React, { useState, useEffect } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import tb from "../../../datatable.module.scss";
import { NavLink } from "react-router-dom";
import { AiFillDelete, AiFillEye, AiOutlineEdit } from "react-icons/ai";
import Tooltip from '@mui/material/Tooltip';
import HStatus from "../../../components/Forms/HStatus";
import DeletePopup from "../../../components/Modals/DeletePopup";
import LocationM from "../../../components/Modals/LocationModal";
import { httpRequest } from "../../../Apis/commonApis";
import { MdEdit } from "react-icons/md";
import AddHelpDocumentModel from "../../../components/Modals/AddHelpDocumentModel";
import Status from "../../../components/Forms/Status";

const StatusButton = (props: any) => {
    let { params, fetchHelpDocuments } = props;
    console.log(params, "param");



    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [editshow, setEditShow] = useState(false);
    // const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);






    const handleEditClose = () => setEditShow(false);
    const handleEditShow = () => setEditShow(true);

    const [showDelete, setDeleteShow] = useState(false);
    const handleDeleteClose = () => setDeleteShow(false);
    const handleDeleteShow = () => setDeleteShow(true);


    // const deletePackages = async () => {
    //     const res = await httpRequest(`deletePromotion/${params?.id}`, "DELETE", null, "withoutToken");

    //     console.log(res, "lll")
    //     if (res?.status == true) {
    //         fetchHelpDocuments && fetchHelpDocuments()
    //         setTimeout(() => {
    //             handleDeleteClose()

    //         }, 1000)

    //         // window.location.reload();
    //     } else {
    //     }
    // };
    // const res = await httpRequest("getPromotion", "get", null, null);

    return (
        <>
            {/* <div>
        <ul className={`${tb.actionTable}`}>
          <li>
            <NavLink
              className={`btn ${tb.delete}`}
              title="Delete"
              to="#"
              onClick={handleDeleteShow}
            >
              <AiFillDelete />
            </NavLink>


          </li>

          <li>
            <NavLink
              className={`btn ${tb.edit}`}
              title="Edit"
              to={`/settings/add-promotion`}
              state={{permotionId : params?.row?.id}}
            >
              <AiOutlineEdit />
            </NavLink>
          </li>
         
        </ul>
      </div> */}
            <div>
                <ul className={`${tb.actionTable}`}>
                    <li>
                        <NavLink
                            className={`btn ${tb.edit}`}
                            title="Edit"
                            state={{ HelpId: params?.row?.id }}
                            to={'/settings/edit-helpdocument'}
                        >
                            <MdEdit />
                        </NavLink>
                    </li>
                    {/* <li>
                        <NavLink
                            className={`btn ${tb.delete}`}
                            title="Delete"
                            to="#"
                            onClick={handleDeleteShow}
                        >
                            <AiFillDelete />
                        </NavLink>
                    // </li> */}
                    <li>
                        <Status status={params?.row?.status} item={params?.row?.screenNumber || ''} api="helpDocuments_status" id={params?.id} getLatest={fetchHelpDocuments}
                            apiMethod="json" />
                        {/* <HStatus status={params?.row?.status} api="helpDocuments_status" id={params?.id} getLatest={fetchHelpDocuments}
                            apiMethod="json" /> */}
                    </li>
                </ul>
            </div>
            <LocationM show={show} handleClose={handleClose} />
            {/* <DeletePopup show={showDelete} deletePackages={deletePackages} handleDeleteClose={handleDeleteClose} /> */}
        </>
    );
};

const renderCellWithTooltip = (params: any) => (
    <Tooltip title={params?.value}>
        <div>{params?.value}</div>
    </Tooltip>
);

const ViewButton = (props: any) => {
    const { params, fetchHelpDocuments } = props;
    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    return (
        <div>
            <ul className={`${tb.actionTable}`}>
                <li>
                    <button
                        className={`btn ${tb.edit}`}
                        title="View"
                        onClick={handleShow}
                    >
                        <AiFillEye />
                    </button>
                </li>
            </ul>
            {show && <AddHelpDocumentModel fetchHelpDocuments={fetchHelpDocuments} show={show} handleClose={handleClose} data={params?.row} />}
        </div>


    );
};

export default function TableDaddtaGrid(props: any) {
    let { promotion, fetchHelpDocuments } = props;

    console.log(fetchHelpDocuments, "lkkkkkk")
    // const { promotionData } = props; 

    const columns = [
        { field: "Sr_No", headerName: "Sr No", flex: 1, minWidth: 60 },
        {
            field: "screenNumber",
            headerName: "Screen No",
            flex: 2,
            minWidth: 90,
            renderCell: renderCellWithTooltip,
        },
        {
            field: "action",
            // headerName: "Action(Activate/Change/Delete)",
            headerName: "Action",
            flex: 1,
            minWidth: 170,
            sortable: true, // Enable sorting
            sortComparator: (v1: any, v2: any) => {
                // Custom comparator for sorting the status (isActive)
                if (v1 === v2) return 0;
                return v1 ? -1 : 1; // Active (true) first, Inactive (false) second
            },
            renderCell: (params: any) => <StatusButton params={params} fetchHelpDocuments={fetchHelpDocuments} />,
        },
        {
            field: "view",
            headerName: "View",
            flex: 1,
            minWidth: 70,
            renderCell: (params: any) => <ViewButton params={params} fetchHelpDocuments={fetchHelpDocuments} />,
        },
        {
            field: "mainMenu",
            headerName: "Main menu",
            flex: 2,
            minWidth: 120,
            renderCell: renderCellWithTooltip,
        },
        {
            field: "sideMenu",
            headerName: "Side Menu",
            flex: 2,
            minWidth: 120,
            renderCell: renderCellWithTooltip,
        },
        {
            field: "field",
            headerName: "Field",
            flex: 2,
            minWidth: 120,
            renderCell: renderCellWithTooltip,
        },
        {
            field: "tab",
            headerName: "Tab",
            flex: 2,
            minWidth: 120,
            renderCell: renderCellWithTooltip,
        },
        {
            field: "changeStatus",
            headerName: "Change/New",
            flex: 2,
            minWidth: 150,
            renderCell: renderCellWithTooltip,
        },
        {
            field: "sequence",
            headerName: "Sequence",
            flex: 2,
            minWidth: 120,
            renderCell: renderCellWithTooltip,
        },
        {
            field: "remark",
            headerName: "Remark",
            flex: 2,
            minWidth: 120,
            renderCell: renderCellWithTooltip,
        },
    ];
    let row: any = promotion?.map((item: any, index: any) => {
        return {
            Sr_No: index + 1,
            status: item?.isActive,
            id: item?._id,
            screenNumber: item?.screenNumber,
            mainMenu: item?.mainMenu,
            sideMenu: item?.sideMenu,
            tab: item?.tab,
            details: item?.details,
            changeStatus: item?.changeStatus,
            sequence: item?.sequence,
            remark: item?.remark,
            field: item?.field,
            action: item?.isActive
        }
    })
    return (
        <div style={{ width: "100%" }}>
            <DataGrid
                columns={columns}
                rows={row}
                autoHeight
                pageSize={100}
                rowHeight={48}
                headerHeight={48}
                checkboxSelection
                disableSelectionOnClick
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
            />
        </div>
    );
}
