import React, { useEffect, useState } from "react";
import cx from "./Help.module.scss";
import pm from "../../../newStyle.module.scss";

// import st from "../../style.module.scss";
import st from "../../../style.module.scss";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { Accordion, Col, Nav, Row, Tab } from "react-bootstrap";
// import { basicInfo, basicInfoA } from "../../../assest";
import basic from "../../../images/basicInfoTab.svg";
import plusV2 from "../../../images/plusV2.svg";
import crossRed from "../../../images/crossRed.svg";
import { httpRequest } from "../../../Apis/commonApis";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from 'react-router-dom';
import { disablePastDate, timeValues } from "../../../constant";
import SettingsMenu from "../../../components/Sidebar/SettingsMenu";
import copyClipBoard from "../../../images/copy-clipboard.svg"
import deleteIcon from "../../../images/deleteIcon.svg"
import editIcon from "../../../images/editIcon.svg"

// import BackArrrow from "../../../components/Business/Buttons/BackArrow";

const AddHelp = (props: any) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    watch,
  } = useForm();

  const navigate = useNavigate();
  const location = useLocation();
  const [helpDocuments, setHelpDocuments] = useState<any | []>([]);
  const [currentId, setCurrentId] = useState(null);
  const [count, setCount] = useState<any>(0);
  const [errorMessage, setErrorMessage] = useState("");




  console.log(count, "count")
  useEffect(() => {
    // window.scrollTo(0, 0);
    fetchHelpDocuments();
  }, []);



  const fetchHelpDocuments = async () => {
    const res = await httpRequest('get_helpDocument', "GET", null, null);
    if (res.status === true) {
      setHelpDocuments(res.data);
    } else {
      console.error("Failed to fetch help documents", res);
    }
  };

  console.log(helpDocuments, count, "helpCunt");





  const onSubmit = async (data: any) => {
    setErrorMessage("")
    let res;
    if (currentId) {
      res = await httpRequest(`update_helpDocuments/${currentId}`, "PATCH", data, "application/json");
    } else {
      res = await httpRequest('create_helpDocuments', "POST", data, "application/json");
    }

    if (res.status === true) {
      setErrorMessage("");
      if (!currentId) {
        setCount((prev: any) => prev + 1)
      }

      // setCount((prev: any) => prev + 1)
      fetchHelpDocuments();
      reset();
      setValue('screenNumber', "");
      setValue('mainMenu', "");
      setValue('sideMenu', "");
      setValue('tab', "");
      setValue('remark', "");
      setValue('changeStatus', "");
      setValue('sequence', "");
      setValue('field', "");
      setCurrentId(null);

    } else {
      setErrorMessage(res?.message || "Failed to create/update help document");
      console.error("Failed to create/update help document", res);
      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
    }
  };


  const handleEdit = (doc: any) => {
    setCurrentId(doc._id);
    setValue("sequence", doc.sequence);
    setValue("screenNumber", doc.screenNumber);
    setValue("mainMenu", doc.mainMenu);
    setValue("sideMenu", doc.sideMenu);
    setValue("tab", doc.tab);
    setValue("changeStatus", doc.changeStatus);
    setValue("remark", doc.remark);
    setValue("field", doc.field);
  };


  // const handleDelete = async (id: any) => {
  //   const res = await httpRequest(`delete_HelpDocument/${id}`, "DELETE", null, null);
  //   if (res.status === true) {
  //     fetchHelpDocuments();
  //   } else {
  //     console.error("Failed to delete help document", res);
  //   }
  // };

  const handleCopy = (doc: any) => {
    navigator.clipboard.writeText(JSON.stringify(doc));
  };


  const handleSave = () => {
    navigate('/settings/helpdocument')
  }
  return (
    <section
      className={`${pm.pageWrapper}`}
      style={{ overflowY: "auto", height: "100%", paddingBottom: "80px" }}
    >
      <div className={`${pm.pageTitle}`}>
        <div className={`${pm.titleInfo}`}>
          Add Help Document
          {/* {promotion?.length !== 0 ? "Edit Promotion" : "Add Promotion"} */}
        </div>
        <div className={`${pm.rightInfo}`}></div>
      </div>

      <div className={`${pm.tabbingBox}`}>
        <Tab.Container id="left-tabs-example" defaultActiveKey="tabNew1">
          <Nav variant="pills">
            <Nav.Item>
              <Nav.Link eventKey="tabNew1">
                <img src={basic} alt="info" className={`${pm.default}`} />
                Details
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="tabNew1">
              <Row>
                <Col xl={1} lg={2} md={3} className="px-2">
                  <div className={cx.price_categorySelect}>
                    <div className={pm.formBox}>
                      <label className="form-label">Screen No.</label>
                      {/* <input
                        className="form-control"
                        placeholder="Screen No."
                        {...register("screenNumber", { required: true })}
                      /> */}
                    <input
                        className="form-control"
                        placeholder="Screen No."
                        type="text" 
                        {...register("screenNumber", {
                          required: "Screen number is required",
                          pattern: {
                            value: /^\d{1,4}$/,
                            message: "Screen number must be up to 4 digits",
                          },
                          validate: {
                            maxLength: value => value.length <= 4 || "Screen number must be up to 4 digits"
                          }
                        })}
                        maxLength={4}
                        onInput={(e) => {
                          const target = e.target as HTMLInputElement;
                          target.value = target.value.replace(/[^0-9]/g, '').slice(0, 4); 
                        }}
                      />
                      {errors.screenNumber && <p className="errorMessage">screenNumber is required</p>}
                      {errorMessage && errors.screenNumber === undefined && <p className="errorMessage">{errorMessage}</p>}

                      {/* {errors.screenNumber && <p className={cx.errorMessage}>{errorMessage}</p>} */}
                      {/* {errors.screenNumber && <p className="errorMessage">screenNumber is required</p>}  {errorMessage && <p className="errorMessage">{errorMessage}</p>} */}
                    </div>
                  </div>
                </Col>
                <Col xl={2} lg={4} md={6} className="px-2">
                  <div className={cx.price_categorySelect}>
                    <div className={pm.formBox}>
                      <label className="form-label">Main Menu</label>
                      <input
                        className="form-control"
                        placeholder="Main Menu"
                        {...register("mainMenu" ,{ required: true })}
                      />
                      {errors.mainMenu && <p className="errorMessage">mainMenu is required</p>}
                    </div>
                  </div>
                </Col>
                <Col xl={2} lg={4} md={6} className="px-2">
                  <div className={cx.price_categorySelect}>
                    <div className={pm.formBox}>
                      <label className="form-label">Side Menu</label>
                      <input
                        className="form-control"
                        placeholder="Side Menu"
                        {...register("sideMenu", )}
                      />
                    
                    </div>
                  </div>
                </Col>
                <Col xl={2} lg={4} md={6} className="px-2">
                  <div className={cx.price_categorySelect}>
                    <div className={pm.formBox}>
                      <label className="form-label">Filed</label>
                      <input
                        className="form-control"
                        placeholder="Filed"
                        {...register("field", )}
                      />
                    
                    </div>
                  </div>
                </Col>
                <Col xl={3}>
                  <Row>
                    <Col xl={4} lg={4} md={6} className="px-2">
                      <div className={cx.price_categorySelect}>
                        <div className={pm.formBox}>
                          <label className="form-label">Tab</label>
                          <input
                            className="form-control"
                            placeholder="Tab"
                            {...register("tab")}
                          />
                         
                        </div>
                      </div>
                    </Col>
                    <Col xl={5} lg={4} md={6} className="px-2">
                      <div className={cx.price_categorySelect}>
                        <div className={pm.formBox}>
                          <label className="form-label">Change/New</label>
                          <input
                            className="form-control"
                            placeholder="Change/New"
                            {...register("changeStatus", { required: true })}
                          />
                          {errors.changeStatus && <p className="errorMessage">Change is required</p>}
                        </div>
                      </div>
                    </Col>
                    <Col xl={3} lg={2} md={6} className="px-2">
                      <div className={cx.price_categorySelect}>
                        <div className={pm.formBox}>
                          <label className="form-label">SQ</label>
                          <input
                            className="form-control"
                            placeholder="SQ"
                            type="number"
                            {...register("sequence", { required: true })}
                          />
                          {errors.sequence && <p className="errorMessage">sequence is required</p>}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xl={2} lg={4} md={6} className="px-2">
                  <div className={cx.price_categorySelect}>
                    <div className={pm.formBox}>
                      <label className="form-label">Remark</label>
                      <select
                        className="form-select"
                        {...register("remark", { required: true })}

                      >
                        <option value="All">All</option>
                        {/* {teamTitleList?.map((item) => (
                            <option key={item?._id} value={item?._id}>
                              {item?.teamTitle}
                            </option>
                          ))} */}
                      </select>
                    </div>
                  </div>
                </Col>
                <Col xl={2} lg={4} md={6} className="px-2">
                  <div className={cx.price_categorySelect}>
                    <button className="btn plusBtn" style={{ width: currentId ? "60%" : "25%" }} onClick={handleSubmit(onSubmit)}>{currentId ? "Update" : "Add"}</button>
                  </div>
                </Col>
              </Row>
              <Row>
                {helpDocuments?.slice(0, count)?.map((doc: any, index: any) => (
                  <React.Fragment key={index}>
                    <Col xl={1} lg={2} md={3} className="px-2">
                      <div className={cx.price_categorySelect}>
                        <div className={pm.formBox}>
                          <input
                            className="form-control"
                            placeholder="Screen No."
                            value={doc.screenNumber}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xl={2} lg={4} md={6} className="px-2">
                      <div className={cx.price_categorySelect}>
                        <div className={`${pm.formBox} ${cx.displayBox}`}>
                          <p>{doc?.mainMenu || ""}</p>

                        </div>
                      </div>
                    </Col>
                    <Col xl={2} lg={4} md={6} className="px-2">
                      <div className={cx.price_categorySelect}>
                        {/* <div className={pm.formBox}>
                          <input
                            className="form-control"
                            placeholder="Side Menu"
                            value={doc?.sideMenu}
                          />
                        </div> */}
                        <div className={`${pm.formBox} ${cx.displayBox}`}>
                          <p>{doc?.sideMenu || ""}</p>

                        </div>
                      </div>
                    </Col>
                    <Col xl={3}>
                      <Row>
                        <Col xl={4} lg={4} md={6} className="px-2">
                          <div className={cx.price_categorySelect}>
                            {/* <div className={pm.formBox}>
                              <input
                                className="form-control"
                                placeholder="Tab"
                                value={doc?.tab}
                              />
                            </div> */}
                            <div className={`${pm.formBox} ${cx.displayBox}`}>
                              <p>{doc?.tab || ""}</p>

                            </div>
                          </div>
                        </Col>
                        <Col xl={5} lg={4} md={6} className="px-2">
                          <div className={cx.price_categorySelect}>
                            <div className={pm.formBox}>
                              {/* <input
                                className="form-control"
                                placeholder="Change/New"
                                value={doc?.changeStatus}
                              /> */}
                              <div className={`${pm.formBox} ${cx.displayBox}`}>
                                <p>{doc?.changeStatus || ""}</p>

                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col xl={3} lg={2} md={6} className="px-2">
                          <div className={cx.price_categorySelect}>
                            <div className={pm.formBox}>
                              {/* <input
                                className="form-control"
                                placeholder="SQ"
                                value={doc?.sequence}
                              /> */}
                              <div className={`${pm.formBox} ${cx.displayBox}`}>
                                <p>{doc?.sequence || ""}</p>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col xl={2} lg={4} md={6} className="px-2">
                      <div className={cx.price_categorySelect}>
                        <div className={pm.formBox}>
                          <select className="form-select" value={doc.remark}>
                            <option value="All">All</option>

                          </select>
                        </div>
                      </div>
                    </Col>
                    <Col xl={2} lg={4} md={6} className="px-2">
                      <div className={cx.price_categorySelect}>
                        <div className={cx.edit_delete_btn}>
                          <ul>
                            <li>
                              <button className="btn" onClick={() => handleEdit(doc)}>
                                <img src={editIcon} alt="Edit" />
                              </button>
                            </li>
                            {/* <li>
                              <button className="btn" onClick={() => handleDelete(doc._id)}>
                                <img src={deleteIcon} alt="Delete" />
                              </button>
                            </li> */}
                            {/* <li>
                              <button className="btn" onClick={() => handleCopy(doc)}>
                                <img src={copyClipBoard} alt="Copy" />
                              </button>
                            </li> */}
                          </ul>
                        </div>
                      </div>
                    </Col>
                  </React.Fragment>
                ))}
              </Row>

              <Row>
                <Col md={12}>
                  <div className={`${pm.formBox}`}>
                    <div className={`${pm.btnsGroup}`}>
                      <button
                        className={`btn ${pm.btn1}`}
                        // onClick={(e: any) => handleStepsFormData("prevStep", "info")}
                        onClick={handleSave}
                      >
                        Back
                      </button>

                      <button
                        className={`btn ${pm.btn4}`}
                        onClick={handleSave}
                      // disabled={loading}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>

            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </section>
  );
};

export default AddHelp;
