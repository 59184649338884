import React, { useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Tooltip from '@mui/material/Tooltip';
const moment = require('moment');

const renderCellWithTooltip = (params: any) => (
  <Tooltip title={params?.value}>
    <div>{params?.value}</div>
  </Tooltip>
);
const columns = [
  { field: "Sr_No", headerName: "Sr No", flex: 1, minWidth: 60 },
  {
    field: "booking",
    headerName: "Booking Id",
    flex: 2,
    minWidth: 120,
    renderCell: renderCellWithTooltip,
  },
  {
    field: "client",
    headerName: "Client",
    flex: 2,
    minWidth: 120,
    renderCell: renderCellWithTooltip,
  },
  {
    field: "service",
    headerName: "Service",
    flex: 2,
    minWidth: 120,
    renderCell: renderCellWithTooltip,
  },
  {
    field: "stylist",
    headerName: "Stylist",
    flex: 2,
    minWidth: 120,
    renderCell: renderCellWithTooltip,
  },
  {
    field: "amenities",
    headerName: "Amenities",
    flex: 1,
    minWidth: 180,
    renderCell: renderCellWithTooltip,
  },
  {
    field: "price",
    headerName: "Price($)",
    flex: 2,
    minWidth: 120,
    renderCell: renderCellWithTooltip,
  },
  {
    field: "date",
    headerName: "Date",
    flex: 2,
    minWidth: 120,
    renderCell: renderCellWithTooltip,
  },
  {
    field: "time",
    headerName: "Start Time",
    flex: 2,
    minWidth: 120,
    renderCell: renderCellWithTooltip,
  }, {
    field: "endTime",
    headerName: "End Time",
    flex: 2,
    minWidth: 120,
    renderCell: renderCellWithTooltip,
  },
  {
    field: "duration",
    headerName: "Duration",
    flex: 2,
    minWidth: 120,
    renderCell: renderCellWithTooltip,
  },
  {
    field: "branch",
    headerName: "Branch Name",
    flex: 2,
    minWidth: 120,
    renderCell: renderCellWithTooltip,
  },
  {
    field: "businessName",
    headerName: "Business Name",
    flex: 2,
    minWidth: 160,
    renderCell: renderCellWithTooltip,
  },
];
export default function TableDataGrid(props: any) {
  let { bookingdata } = props;
  let row: any = bookingdata?.map((item: any, index: any) => {
    let duration: any = []
    let amenities: any = []
    item?.services?.map((val: any) => {
      val?.amenitiesId?.map((item: any) => {
        amenities?.push(item?.itemName?.itemName)
      })
      duration?.push(val?.serviceId?.duration)
    });
    const totalDuration = duration.reduce((acc: any, value: any) => {
      const parsedValue = parseInt(value, 10);
      return isNaN(parsedValue) ? acc : acc + parsedValue;
    }, 0);
    const initialTime = moment(item?.salectTime, "HH:mm");
    const newTime = initialTime.add(totalDuration, 'minutes');
    const newTimeString = newTime.format('HH:mm');
    return {
      Sr_No: index + 1,
      id: item?._id,
      businessName: item?.Business?.businessName,
      booking: item?.booking_Id,
      branch: item?.BranchLocation?.branchName,
      service: item?.services?.map((item: any) => item?.serviceDetails?.serviceName)?.join(','),
      client: `${item?.Client?.firstName} ${item?.Client?.lastName}`,
      stylist: item?.services?.map((item: any) => `${item?.TeamMemberId?.[0]?.firstName} ${item?.TeamMemberId?.[0]?.lastName}`)?.join(','),
      price: item?.finalPrice ? `${item?.finalPrice}` : "",
      date: item?.salectDate,
      time: item?.salectTime,
      endTime: newTimeString,
      amenities: [...new Set(amenities)].join(","),
      duration: `${totalDuration} mins`
    }
  })

  return (
    <div style={{ width: "100%" }}>
      <DataGrid
        columns={columns}
        rows={row}
        autoHeight
        pageSize={100}
        rowHeight={48}
        headerHeight={48}
        checkboxSelection
        disableSelectionOnClick
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      />
    </div>
  );
}
