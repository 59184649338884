import React, { useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

import tb from "../../../datatable.module.scss";
import { NavLink } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";

import Status from "../../../components/Forms/Status";
import ServiceCategoryM from "../../../components/Modals/ServiceCategory";
import DeletePopup from "../../../components/Modals/DeletePopup";
import { httpRequest } from "../../../Apis/commonApis";

const StatusButton = (props: any) => {
  let { params } = props;


  const [showDelete, setDeleteShow] = useState(false);
  const handleDeleteClose = () => setDeleteShow(false);
  const handleDeleteShow = () => setDeleteShow(true);

  const deletePackages = async () => {
    const res = await httpRequest(`deleteServiceCategory/${params?.id}`, "DELETE", null, "withoutToken");
    if (res?.status === true) {
      props?.getServiceCatagory && props?.getServiceCatagory()
      setTimeout(() => {
        handleDeleteClose()

      }, 1000)

      // window.location.reload();
    } else {
    }
  };

  return (
    <>
      <div>
        <ul className={`${tb.actionTable}`}>
          <li>
            <NavLink
              className={`btn ${tb.edit}`}
              title="Edit"
              to={'edit-service-category'}
              state={{ _id: params?.row?.id }}

            >
              <MdEdit />
            </NavLink>
          </li>
          <li>
            <NavLink
              className={`btn ${tb.delete}`}
              title="Delete"
              to="#"
              onClick={handleDeleteShow}
            >
              <AiFillDelete />
            </NavLink>
          </li>
          <li>
            <Status status={params?.row?.isActive} api="updateServiceCategory" id={params?.id}
              apiMethod="json" getLatest={props.getServiceCatagory} item={params?.row?.serviceCategory || ''} />
          </li>
        </ul>
      </div>

      <DeletePopup show={showDelete} deletePackages={deletePackages} handleDeleteClose={handleDeleteClose} />
    </>
  );
};

export default function TableDataGrid({ catData, getServiceCatagory }: any) {
  const columns = [
    { field: "serialNumber", headerName: "S.R.", flex: 1, minWidth: 60 },
    {
      field: "action",
      headerName: "Action",
      flex: 2,
      minWidth: 220,
      sortable: true, // Enable sorting
      sortComparator: (v1: any, v2: any) => {
        // Custom comparator for sorting the status (isActive)
        if (v1 === v2) return 0;
        return v1 ? -1 : 1; // Active (true) first, Inactive (false) second
      },
      renderCell: (params: any) => (
        <StatusButton
          catData={catData}
          params={params}
          getServiceCatagory={getServiceCatagory}
        />
      ),
    },
    {
      field: "serviceCategory",
      headerName: "Service Category",
      flex: 2,
      minWidth: 180,
    },
    {
      field: "businessCategory",
      headerName: "Business Category",
      flex: 2,
      minWidth: 180,
    },
  ];
  let rows: any = []

  if (catData?.length > 0) {
    rows = catData?.map((val: any, index: any) => {
      return {
        id: val?._id,
        serialNumber: index + 1,
        serviceCategory: val?.serviceCategoryName,
        businessCategory: val?.businessCategoryId ? val?.businessCategoryId?.map((item: any) => item?.BusinessCategoryName)?.join(",") : "",
        catDetail: val?.serviceCategoryDetails,
        catTag: val?.serviceCategoryTag,
        action: val?.isActive,
        isActive: val?.isActive
      };
    });
  }

  return (
    <div style={{ width: "100%" }}>
      <DataGrid
        columns={columns}
        rows={rows}
        //onRowClick={handleRowClick}
        autoHeight
        hideFooterPagination={true}
        rowHeight={48}
        headerHeight={48}
        checkboxSelection
        disableSelectionOnClick
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      />
    </div>
  );
}
