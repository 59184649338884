import React, { useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import tb from "../../../datatable.module.scss";
import { NavLink } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import { AiFillDelete, AiFillEye } from "react-icons/ai";
import Checkbox from "../../../components/Forms/Checkbox";
import st from "../../../style.module.scss";

import category1 from "../../../images/Chair.svg";
import Status from "../../../components/Forms/Status";
import AmenitiesM from "../../../components/Modals/Amenities";
import DeletePopup from "../../../components/Modals/DeletePopup";
import ImageManagementM from "../../../components/Modals/ImageManagementModal";
import ViewKeywordModal from "../../../components/Modals/ViewKeywordModal";
import OnOffSwitch from "../../../components/Forms/OnOffSwitch";
import EditBussinessManagement from "../../../components/Modals/EditBussinessManagement";
import { httpRequest } from "../../../Apis/commonApis";
import ViewCategoryModal from "../../../components/Modals/ViewCategoryModal";
import BusinessContactDetails from "../../../components/Modals/BusinessContactDetails";
import SuccessPasswordModal from "../../../components/Modals/SuccessPasswordModal";

const StatusButton = (props: any) => {
    let { params, data } = props;
    let getBusinessInfoData = props?.getBusinessInfo
    let allData = props?.data
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setEditId(null)
        setShow(false)
    };
    const [editId, setEditId] = useState<any>(null)
    const handleShow = (id: any) => {
        setEditId(id)
        setShow(true);
    }

    const [showBusinessContact, setBusinessContactShow] = useState(false);
    const handleBusinessContactClose = () => setBusinessContactShow(false);
    // const handleBusinessContactShow = () => setBusinessContactShow(true);



    const [businessContactId, setBusinessContactId] = useState<any>(null);
    console.log(businessContactId, "businessContactId")

    const handleBusinessContactShow = (id: any) => {
        const selectedBusinessObj = allData?.find((item: any) => item?._id === id)
        console.log(selectedBusinessObj, "selectedBusinessObj")
        setBusinessContactId(selectedBusinessObj);
        setBusinessContactShow(true);
    };

    // const businessContactDetailsData = allData?.find((item: any) => item._id === businessContactId);
    return (
        <>
            <div>
                <ul className={`${tb.actionTable}`}>
                    <li>
                        <NavLink
                            className={`btn ${tb.edit}`}
                            title="Edit"
                            to="#"
                            onClick={() => handleShow(params?.row?.id)}
                        >
                            <MdEdit />
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            className={`btn ${tb.delete}`}
                            title="View"
                            to="#"
                            onClick={() => handleBusinessContactShow(params?.row?.id)}
                        // state={{ pageId: params?.row?.id }}
                        // onClick={handleShow}
                        >
                            <AiFillEye />
                        </NavLink>


                    </li>
                    <li>
                        {/* <Status /> */}
                        <Status status={params?.row?.status} item={`${params?.row?.businessName} B2C` || ''} api="business_Info" id={params?.row?.id} apiMethod="json" getLatest={getBusinessInfoData} defaultName="B2C" />

                    </li>
                </ul>
            </div>
            {showBusinessContact && <BusinessContactDetails show={showBusinessContact} handleBusinessContactClose={handleBusinessContactClose} ContactDetailsData={businessContactId} />}

            {show && <EditBussinessManagement show={show} handleClose={handleClose} data={editId} getBusinessInfoData={getBusinessInfoData} />}


            {/* <DeletePopup show={showDelete} handleDeleteClose={handleDeleteClose} /> */}
        </>
    );
};

const ViewButton = ({ params }: any) => {
    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    return (
        <>
            <div>
                <ul className={tb.actionTable}>
                    <li>
                        <button className={`btn ${tb.edit}`} title="View" onClick={handleShow}>
                            <AiFillEye />
                        </button>
                    </li>
                </ul>
            </div>
            {show && <ViewCategoryModal show={show} handleClose={handleClose} categories={params?.row?.viewCategory} />}
        </>
    );
};




const ViewKeywordButton = ({ params }: any) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <div>
                <ul className={`${tb.actionTable}`}>
                    <li>
                        <button className={`btn ${tb.edit}`} title="View Keywords" onClick={handleShow}>
                            <AiFillEye />
                        </button>
                        <ViewKeywordModal show={show} handleClose={handleClose} keywords={params?.row?.searchArray} />
                    </li>
                </ul>
            </div>
        </>
    );
};



async function stausHandler(e: any, params: any, getBusinessInfo: any, type: string) {
    // console.log(params, "dataorginin", e);
    const checked = e.target.checked
    const res = await httpRequest(`business_Info/${params?.row?.id}`, "PATCH", { [type]: checked }, "json");
    // const res = await httpRequest(`business_Info/${data?._id}`, "patch", null, "withoutToken");
    // console.log(res?.data, "res")
    if (res?.status == true) {
        // setLoading(false)
        getBusinessInfo && getBusinessInfo()

    }
}

async function statusTranding(e: any, params: any, getBusinessInfo: any) {
    // console.log(params, "dataorginin", e);
    const checked = e.target.checked
    const res = await httpRequest(`business_Info/${params?.row?.id}`, "PATCH", { trending: checked }, "json");
    // const res = await httpRequest(`business_Info/${data?._id}`, "patch", null, "withoutToken");
    // console.log(res?.data, "res")
    if (res?.status == true) {
        // setLoading(false)
        getBusinessInfo && getBusinessInfo()

    }
}
const PublishComp = (props: any) => {
    let { params, getBusinessInfo } = props;
    const pubStatus = params?.allData?.isPublished;
    const [message, setMessage] = useState('');
    const [show, setShow] = useState<boolean>(false);
    function handleClose() {
        setMessage('')
        setShow(false)
    }
    const confirmationResponse = async () => {
        const res = await httpRequest(`updateBusiness/${params?.id}`, "PATCH", { isPublished: 'published' }, "withoutToken");
        if (res?.status) {
            setMessage('business publish successfully')
            setShow(true)
            getBusinessInfo && getBusinessInfo()
        }
    }


    function handlePublish() {
        setMessage('Do you want to publish this business')
        setShow(true)
    }


    return (
        <>
            {show && <SuccessPasswordModal type={message === 'Do you want to publish this business' ? 'info' : 'success'} handleClose={handleClose} show={show} message={message} confirmationResponse={confirmationResponse} />}
            <div>
                <ul className={`${tb.actionTable}`}>
                    <li className={`${tb.publish} gap-2 d-flex`}>
                        <span className={`${pubStatus ? pubStatus === 'inProgress' ? tb?.pending : pubStatus === 'unPublished' ? tb.unpub : tb.pub : tb.unpub}`}>{pubStatus ? pubStatus === 'inProgress' ? 'Pending' : pubStatus === 'unPublished' ? 'UnPublished' : pubStatus : 'Unpublish'}</span>
                        {(pubStatus === 'inProgress') && <button onClick={handlePublish}>{pubStatus === 'inProgress' ? "Click to Publish" : "Click to UnPublish"}</button>}
                    </li>
                </ul>
            </div>
        </>
    );
};


export default function TableDataGrid(props: any) {
    let data: any = props?.businessUserData;
    let getBusinessInfo = props?.getBusinessInfo;

    const columns = [
        { field: "srNo", headerName: "Sr No", flex: 1, minWidth: 60, headerClassName: 'fixed-header', cellClassName: 'fixed-cell' },
        { field: "businessName", headerName: "Business Name", flex: 1, minWidth: 150, headerClassName: 'fixed-header', cellClassName: 'fixed-cell' },

        { field: "businessAccountNo", headerName: "Business ID", flex: 1, minWidth: 110 },
        {
            field: "searchArray",
            headerName: "Search Index",
            flex: 1,
            minWidth: 70,
            renderCell: (params: any) => <ViewKeywordButton params={params} />,
        },
        {
            field: "action",
            headerName: "Action",
            flex: 1, minWidth: 280,
            sortable: true, // Enable sorting
            sortComparator: (v1: any, v2: any) => {
                // Custom comparator for sorting the status (isActive)
                if (v1 === v2) return 0;
                return v1 ? -1 : 1; // Active (true) first, Inactive (false) second
            },
            renderCell: (params: any) => <StatusButton params={params} data={data} getBusinessInfo={getBusinessInfo} />,
        },
        // {
        //     field: "publish",
        //     headerName: "Publish",
        //     flex: 1, minWidth: 180,

        // },
        {
            field: "AccessStatus",
            headerName: " Access Status",
            flex: 1, minWidth: 180,
            sortable: true, // Enable sorting
            sortComparator: (v1: any, v2: any) => {
                // Custom comparator for sorting the status (isActive)
                if (v1 === v2) return 0;
                return v1 ? -1 : 1; // Active (true) first, Inactive (false) second
            },
            renderCell: (params: any) => {
                return (
                    <Status type={"AccessStatus"} item={`${`${params?.row?.businessName}` || ''}`} status={params?.row?.AccessStatus} api="business_Info" id={params?.row?.id} apiMethod="json" getLatest={getBusinessInfo} />
                )
            },
        },
        {
            field: "viewCategory",
            headerName: "Category",
            flex: 1,
            minWidth: 110,
            maxWidth: 110,
            renderCell: (params: any) => <ViewButton params={params} />,
        },
        {
            field: "publishStatus",
            headerName: "Publish Status",
            flex: 2,
            minWidth: 220,
            sortable: true, // Enable sorting
            sortComparator: (v1: any, v2: any) => {
                if (v1 === v2) return 0;
                return v1 === 'published' ? -1 : 1;
            },
            renderCell: (params: any) => <PublishComp params={params?.row} getBusinessInfo={props.getBusinessInfo} />
        },
        {
            field: "trending",
            headerName: "Trending",
            flex: 1,
            minWidth: 110,
            maxWidth: 110,
            sortable: true, // Enable sorting
            sortComparator: (v1: any, v2: any) => {
                if (v1 === v2) return 0;
                return v1 ? -1 : 1;
            },
            renderCell: (params: any) => <OnOffSwitch params={params} checked={params?.row?.trending} handleChange={(e: any) => stausHandler(e, params, getBusinessInfo, "trending")} actionType="trending" />,
        },
        {
            field: "featured",
            headerName: "Featured",
            flex: 1,
            minWidth: 110,
            maxWidth: 110,
            sortable: true, // Enable sorting
            sortComparator: (v1: any, v2: any) => {
                console.log(v1, v2, 'v1v2222222');
                if (v1 === v2) return 0;
                return v1 === 'published' ? -1 : 1;
            },
            renderCell: (params: any) => <OnOffSwitch params={params} checked={params?.row?.featured} handleChange={(e: any) => stausHandler(e, params, getBusinessInfo, "featured")} actionType="featured" />,
        },
        {
            field: "promotion",
            headerName: "Promotion",
            flex: 1,
            minWidth: 110,
            maxWidth: 110,
            sortable: true, // Enable sorting
            sortComparator: (v1: any, v2: any) => {
                if (v1 === v2) return 0;
                return v1 === 'published' ? -1 : 1;
            },
            renderCell: (params: any) => <OnOffSwitch params={params} checked={params?.row?.hasPromotion} handleChange={(e: any) => stausHandler(e, params, getBusinessInfo, 'hasPromotion')} actionType="Promotion Status" />,
        },
        {
            field: "email",
            headerName: "Email Verified",
            flex: 1, minWidth: 130,
        },
        {
            field: "phone",
            headerName: "Phone Verified",
            flex: 1, minWidth: 130,
        },
        {
            field: "attribute1",
            headerName: "Attribute 1",
            flex: 1, minWidth: 130,
        },
        {
            field: "attribute2",
            headerName: "Attribute2",
            flex: 1, minWidth: 130,
        },
        {
            field: "attribute3",
            headerName: "Attribute 3",
            flex: 1, minWidth: 130,
        },
        {
            field: "priority",
            headerName: "Priority Attribute",
            flex: 1, minWidth: 130,
        },

    ];

    const startIndex = 0;
    const row = data?.map((val: any, index: any) => {
        const cat = val?.businessCatagoryId?.map((item: any) => item?.BusinessCategoryName) || [];
        const srNo = startIndex + index + 1;
        //   const Keyword = val?.searchArray?.map((data:any,index:number)=>{
        //     return data
        //   })
        //   console.log(cat,"Keyword")
        const Keyword1 = val?.attribute1?.map((item: any) => item?.name)
        const Keyword2 = val?.attribute2?.map((item: any) => item?.name)
        const Keyword3 = val?.attribute3?.map((item: any) => item?.name)
        return {

            id: val?._id,
            srNo,
            allData: val,
            publish: val?.isPublished,
            AccessStatus: val?.AccessStatus,
            businessName: val?.businessName,
            businessAccountNo: val?.businessAccountNo,
            searchArray: val?.searchArray,
            viewCategory: cat,
            hasPromotion: val?.hasPromotion,
            promotion:val?.hasPromotion,
            customerType: val?.customerType,
            featured: val?.featured,
            trending: val?.trending,
            publishStatus: val?.isPublished,
            email: val?.BusinessUser?.emailVerified,
            phone: val?.BusinessUser?.mobileVerified,
            //   searchIndex: val.searchIndex,
            attribute1: Keyword1,
            attribute2: Keyword2,
            attribute3: Keyword3,
            priority: val?.priority,
            action: val?.isActive,
            status: val?.isActive,
        };
    });

    //   const row = [
    //     {
    //       id: 1,
    //       customerName: "Ashok Kumar",
    //       viewData: "Ashok Kumar",
    //       businessId: "B001",
    //       viewCategory: "Category 1",
    //       customerType: "Regular",
    //       featured: "Yes",
    //       searchIndex: "High",
    //       tag1: "Tag1",
    //       tag2: "Tag2",
    //       tag3: "Tag3",
    //       priority: "Low",
    //       action: "",
    //     },
    //     {
    //       id: 2,
    //       customerName: "Vijay Sharma",
    //       businessId: "B002",
    //       viewCategory: "Category 2",
    //       customerType: "Premium",
    //       featured: "No",
    //       searchIndex: "Medium",
    //       tag1: "Tag4",
    //       tag2: "Tag5",
    //       tag3: "Tag6",
    //       priority: "High",
    //       action: "",
    //     },
    //     {
    //       id: 3,
    //       customerName: "Sanjay Patel",
    //       businessId: "B003",
    //       viewCategory: "Category 3",
    //       customerType: "Regular",
    //       featured: "Yes",
    //       searchIndex: "Low",
    //       tag1: "Tag7",
    //       tag2: "Tag8",
    //       tag3: "Tag9",
    //       priority: "Medium",
    //       action: "",
    //     },
    //   ];

    return (
        <div className="data-grid-container" style={{ width: "100%" }}>
            <DataGrid
                columns={columns}
                rows={row}
                //onRowClick={handleRowClick}
                autoHeight
                hideFooterPagination={true}
                rowHeight={48}
                headerHeight={48}
                checkboxSelection
                disableSelectionOnClick
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
            />
        </div>
    );
}
