import React from "react";
import cx from "./Sidebar.module.scss";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";

import { MdCategory, MdKeyboardArrowDown, MdListAlt } from "react-icons/md";

// --------- Images --------- //
import student from "../../images/icon-student.svg";
import team from "../../images/icon-team.svg";
import courses from "../../images/icon-course.svg";

const WebpageMenu = () => {
  return (
    <>
      <div className={`${cx.moreMenuList}`}>
        <h5 className={`${cx.moreMenuTitle}`}>
          <div className={`${cx.menuIcon}`}>
            <img src={courses} alt="courses" />
          </div>
          <div className={`${cx.menuName}`}>Webpage set up</div>
        </h5>
        <ul>
          {/* <li>
            <NavLink to="/webpages/section-page" style={{ padding: "6px" }}>
              <div className={`${cx.menuIcon}`}>
                <MdCategory />
              </div>
              <div className={`${cx.menuName}`}>Section page</div>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/webpages/images-management"
              style={{ padding: "6px" }}
            >
              <div className={`${cx.menuIcon}`}>
                <MdCategory />
              </div>
              <div className={`${cx.menuName}`}>Images Management</div>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/webpages/information-management"
              style={{ padding: "6px" }}
            >
              <div className={`${cx.menuIcon}`}>
                <MdCategory />
              </div>
              <div className={`${cx.menuName}`}>CMS</div>
            </NavLink>
          </li> */}
          <li>
            <NavLink
              to="/webpages/business-management"
              style={{ padding: "6px" }}
            >
              <div className={`${cx.menuIcon}`}>
                <MdCategory />
              </div>
              <div className={`${cx.menuName}`}>Business Management </div>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/webpages/business-section"
              style={{ padding: "6px" }}
            >
              <div className={`${cx.menuIcon}`}>
                <MdCategory />
              </div>
              <div className={`${cx.menuName}`}>Home Sections</div>
            </NavLink>
          </li>
          
          <li>
            <NavLink
              to="/webpages/collections"
              style={{ padding: "6px" }}
            >
              <div className={`${cx.menuIcon}`}>
                <MdCategory />
              </div>
              <div className={`${cx.menuName}`}>Collections</div>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/webpages/reward-setting/add-reward"
              style={{ padding: "6px" }}
            >
              <div className={`${cx.menuIcon}`}>
                <MdCategory />
              </div>
              <div className={`${cx.menuName}`}>Reward Setting</div>
            </NavLink>
          </li>
        </ul>
      </div>
    </>
  );
};

export default WebpageMenu;
