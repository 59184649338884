import React, { useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import tb from "../../../datatable.module.scss";
import { NavLink } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import Checkbox from "../../../components/Forms/Checkbox";
import st from "../../../style.module.scss";
import Tooltip from '@mui/material/Tooltip';
import category1 from "../../../images/Chair.svg";
import Status from "../../../components/Forms/Status";
import AmenitiesM from "../../../components/Modals/Amenities";
import DeletePopup from "../../../components/Modals/DeletePopup";
import { httpRequest } from "../../../Apis/commonApis";

const StatusButton = (props: any) => {
  let { params, getTeamTitle } = props;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [error, seterror] = useState("");
  const [showDelete, setDeleteShow] = useState(false);
  const handleDeleteClose = () => setDeleteShow(false);
  const handleDeleteShow = () => setDeleteShow(true);

  const deleteTeamTitle = async () => {
    const res = await httpRequest(`delete_team_title/${params?.id}`, "DELETE", null, "withoutToken");
    if (res?.status == true) {
      window.location.reload();
    } else {
      seterror(res?.message);
    }
  };
  return (
    <>
      <div>
        <ul className={`${tb.actionTable}`}>
          <li>
            <NavLink
              className={`btn ${tb.delete}`}
              title="Delete"
              to="#"
              onClick={handleDeleteShow}
            >
              <AiFillDelete />
            </NavLink>
          </li>
          <li>
            {/* <Status teamTitle={params?.id}/> */}
            {/* <Status status={params?.status} api="team_title_status" id={params?.id}
              apiMethod="json" getLatest={getTeamTitle} /> */}
          </li>
        </ul>
      </div>

      <AmenitiesM show={show} handleClose={handleClose} />
      <DeletePopup
        show={showDelete}
        deleteTeamTitle={deleteTeamTitle}
        handleDeleteClose={handleDeleteClose}
      />
    </>
  );
};

const renderCellWithTooltip = (params: any) => (
  <Tooltip title={params?.value}>
    <div>{params?.value}</div>
  </Tooltip>
);

export default function TableDataGrid(props: any) {
  let { listTeamTitle, getTeamTitle } = props;
  let row: any = props?.listTeamTitle?.map((val: any, index: any) => {
    return {
      Sr_No: index + 1,
      id: val?._id,
      teamTitle: val?.teamTitle,
      titleID: val?.titleID,
      businessId: val?.Business?.businessName,
      countryId: val?.businessId?.country,
      createdBy: val?.createdBy,
      status: val?.status,
      updatedBy: val?.updatedBy,
      action: "",
    };
  });


  const columns = [
    { field: "Sr_No", headerName: "Sr No", flex: 1, minWidth: 10, renderCell: renderCellWithTooltip },
    // {
    //   field: "action",
    //   headerName: "Action",
    //   flex: 1,
    //   minWidth: 180,
    //   renderCell: (params: any) => <StatusButton params={params?.row} getTeamTitle={getTeamTitle} />
    // },
    { field: "teamTitle", headerName: "Team Title", flex: 1, minWidth: 160, renderCell: renderCellWithTooltip },
    { field: "titleID", headerName: "Title ID", flex: 1, minWidth: 100, renderCell: renderCellWithTooltip },
    { field: "businessId", headerName: "Business Name", flex: 1, minWidth: 100, renderCell: renderCellWithTooltip },
    // { field: "countryId", headerName: "Country", flex: 1, minWidth: 100, renderCell: renderCellWithTooltip },
    { field: "createdBy", headerName: "Created By", flex: 1, minWidth: 100, renderCell: renderCellWithTooltip },
    { field: "updatedBy", headerName: "Updated By", flex: 1, minWidth: 100, renderCell: renderCellWithTooltip },

  ];
  return (
    <div style={{ width: "100%" }}>
      <DataGrid
        columns={columns}
        rows={row}
        pageSize={100}
        //onRowClick={handleRowClick}
        autoHeight
        // hideFooterPagination={true}
        rowHeight={48}
        headerHeight={48}
        checkboxSelection
        disableSelectionOnClick
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      />
    </div>
  );
}
