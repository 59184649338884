export const httpRequest = async (api: string, method: string, body: any, dataType: any) => {
    let token = localStorage.getItem("superAdmin_token") || '';
    
    let BASE_URL = process.env.REACT_APP_BASEURL;
    try {
      if (dataType === "json") {
        const res = await fetch(`${BASE_URL}/${api}`, {
          method: method,
          headers: {
            "Content-Type": "application/json",
            Authorization:token,
          },
          body: body ? JSON.stringify(body) : null,
        });
        return await res.json();
      } else if (dataType === 'formdata'){
        const res = await fetch(`${BASE_URL}/${api}`, {
          method: method,
          headers: {
            Authorization: token,
          },
          body: body
        });
        return await res.json();
      }else{
        const res = await fetch(`${BASE_URL}/${api}`, {
          method: method,
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: body ? JSON.stringify(body) : null,
        });
        return await res.json();
      }
    } catch (error) {
      return await error;
    }
  };