import React, { useState, useEffect } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import tb from "../../../datatable.module.scss";
import { NavLink } from "react-router-dom";
import { AiFillDelete, AiOutlineEdit } from "react-icons/ai";
import Tooltip from '@mui/material/Tooltip';
import Status from "../../../components/Forms/Status";
import DeletePopup from "../../../components/Modals/DeletePopup";
import LocationM from "../../../components/Modals/LocationModal";
import { httpRequest } from "../../../Apis/commonApis";

const StatusButton = (props: any) => {
  let { params, getData } = props;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [editshow, setEditShow] = useState(false);
  // const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleEditClose = () => setEditShow(false);
  const handleEditShow = () => setEditShow(true);

  const [showDelete, setDeleteShow] = useState(false);
  const handleDeleteClose = () => setDeleteShow(false);
  const handleDeleteShow = () => setDeleteShow(true);

  const deletePackages = async () => {
    const res = await httpRequest(`deletePromotion/${params?.id}`, "DELETE", null, "withoutToken");
    if (res?.status === true) {
      getData && getData()
      setTimeout(() => {
        handleDeleteClose()

      }, 1000)

      // window.location.reload();
    } else {
    }
  };
  // const res = await httpRequest("getPromotion", "get", null, null);

  return (
    <>
      <div>
        <ul className={`${tb.actionTable}`}>
          <li>
            <NavLink
              className={`btn ${tb.delete}`}
              title="Delete"
              to="#"
              onClick={handleDeleteShow}
            >
              <AiFillDelete />
            </NavLink>


          </li>

          <li>
            <NavLink
              className={`btn ${tb.edit}`}
              title="Edit"
              to={`/settings/add-promotion`}
              state={{ permotionId: params?.row?.id }}
            >
              <AiOutlineEdit />
            </NavLink>
          </li>
          <li>
            <Status status={params?.row?.status} api="updatePromotion" item={params?.row?.promotionName || ''} id={params?.row?.id}
              apiMethod="formdata" getLatest={getData} />
          </li>
        </ul>
      </div>
      <LocationM show={show} handleClose={handleClose} />
      <DeletePopup show={showDelete} deletePackages={deletePackages} handleDeleteClose={handleDeleteClose} />
    </>
  );
};

const renderCellWithTooltip = (params: any) => (
  <Tooltip title={params?.value}>
    <div>{params?.value}</div>
  </Tooltip>
);



export default function TableDaddtaGrid(props: any) {
  let { promotion, getData } = props;
  // const { promotionData } = props; 

  const columns = [
    { field: "Sr_No", headerName: "Sr No", flex: 1, minWidth: 60 },
    {
      field: "promotionName",
      headerName: "Promotion",
      flex: 2,
      minWidth: 120,
      renderCell: renderCellWithTooltip,
    },
    {
      field: "promotionType",
      headerName: "Promotion Type",
      flex: 2,
      minWidth: 120,
      renderCell: renderCellWithTooltip,
    },
    {
      field: "selldateFrom",
      headerName: "Sell Date Start",
      flex: 2,
      minWidth: 120,
      renderCell: renderCellWithTooltip,
    },
    {
      field: "selldateTo",
      headerName: "Sell Date End",
      flex: 2,
      minWidth: 120,
      renderCell: renderCellWithTooltip,
    },
    {
      field: "bookingdateFrom",
      headerName: "Booking Date Start",
      flex: 2,
      minWidth: 150,
      renderCell: renderCellWithTooltip,
    },
    {
      field: "discount",
      headerName: "discount",
      flex: 2,
      minWidth: 120,
      renderCell: renderCellWithTooltip,
    },
    {
      field: "image",
      headerName: "Sticker",
      flex: 2,
      minWidth: 120,
      renderCell: (params: any) => (
        params.value ?
          <img src={params.value} alt="Sticker" style={{ width: 30, height: 30, borderRadius: "50%" }} /> :
          <div role="img">

          </div>
      ),
    },

    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 280,
      sortable: true, // Enable sorting
      sortComparator: (v1: any, v2: any) => {
        // Custom comparator for sorting the status (isActive)
        if (v1 === v2) return 0;
        return v1 ? -1 : 1; // Active (true) first, Inactive (false) second
      },
      renderCell: (params: any) => <StatusButton params={params} getData={getData} />,
    },

  ];
  let row: any = promotion?.map((item: any, index: any) => {
    return {
      Sr_No: index + 1,
      id: item?._id,
      promotionName: item?.promotionName,
      promotionType: item?.promotionType,
      selldateFrom: item?.selldateFrom || '--',
      selldateTo: item?.selldateTo || '--',
      bookingdateFrom: item?.bookingdateFrom || '--',
      discount: item?.discount || '--',
      image: item?.image,
      action: item?.isActive,
      status: item?.isActive,
      // service: item?.priceId?.map((item: any) => item?.id?.serviceId?.serviceName)?.join(','),
      // date: item?.dateFrom != null ? `${item?.dateFrom} to ${item?.dateTo}` : "-----",
      // time: item?.startTimeFrom != null ? `${item?.startTimeFrom} to ${item?.endTimeTo}` : "-----",
      // businessName: item?.Business?.businessName,

    }
  })
  return (
    <div style={{ width: "100%" }}>
      <DataGrid
        columns={columns}
        rows={row}
        autoHeight
        pageSize={100}
        rowHeight={48}
        headerHeight={48}
        checkboxSelection
        disableSelectionOnClick
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      />
    </div>
  );
}
