import React from "react";
import m from "./Modal.module.scss";
import { NavLink } from "react-router-dom";
import { Modal } from "react-bootstrap";

const DeletePopup = (props: any) => {
  let { deleteselectedUsers,show,userId, handleDeleteClose,deletebusinessUser,deleteCatagoryApi,deletebusinessInfo,deleteBranch,deleteServiceCategory,deleteTeamMember,deleteTeamTitle,deleteClient,deleteAmenities,deleteService,deletePackages,deleteServiceTag } = props;
  
  const deleteFunctions = [userId,deletebusinessUser,deleteCatagoryApi,deletebusinessInfo,deleteBranch,deleteServiceCategory,
    deleteTeamMember,deleteTeamTitle,deleteselectedUsers,deleteClient,deleteAmenities,deleteService,deletePackages,deleteServiceTag];

    const activeDeleteFunction = deleteFunctions.find((func) => func !== undefined);
  return (
    <>
      <Modal
        centered
        scrollable
        show={show}
        onHide={handleDeleteClose}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <div className={m.modal}>
          <div className={m.top}>Delete</div>
          <div className={`${m.logoutPopup}`}>
            <h3>
              Do you want to delete this{" "}
              <span className={m.deleteItem}>item</span>?
            </h3>
            <div className={`${m.btnsAlignments}`}>
              <NavLink
                className={`btn ${m.cancelBtn}`}
                to=""
                onClick={handleDeleteClose}
              >
                Cancel
              </NavLink>
              <button
                type="button"
                className={`btn ${m.actionBtn}`}
                onClick={activeDeleteFunction || handleDeleteClose}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DeletePopup;
