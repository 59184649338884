import React, { useCallback, useEffect, useState } from "react";
import st from "../../../style.module.scss";
import tb from "../../../datatable.module.scss";
import { Card, Row, Col } from "react-bootstrap";
import TableDataGrid from "./DataGrid";
import BusinessMenu from "../../../components/Sidebar/BusinessMenu";
import { httpRequest } from "../../../Apis/commonApis";

export default function BookingData() {
  const [bookingdata, setBookingdata] = useState<any[]>([])
  const [active, setActive] = useState("Upcoming")

  useEffect(() => {
    getBooking("Upcoming")
  }, [])
  const getBooking = async (e: any) => {
    let res = await httpRequest(`getBooking?desiredStatus=${e}`, "get", null, "auth");
    if (res.status) {
      setBookingdata(res?.data)
    }
  };
  const submit = async (e: any) => {
    setActive(e)
    getBooking(e)
  }

  return (
    <>
      <section className={`${st.pageWrapper} ${st.pageWrapperOpen}`}>
        <BusinessMenu />
        <div className={`${st.pageTitle}`}>
          <Row>
            <Col md={6} lg={6}>
              <div className={`${st.titleInfo}`}>
                <div className={`${st.titleInfoBody}`}>
                  <h5>Business</h5>
                  <p>Booking</p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className={`${st.pageWrapperInside}`}>
          <Card>
            <Card.Body>
              <Row className={`${tb.tableAction}`}>
                <Col lg={12}>
                  <div className="d-flex">
                    <button
                      className={`btn ${tb.upload} ${active === "Upcoming" ? tb.active : ""}`}
                      onClick={() => submit("Upcoming")}
                    >
                      Upcoming
                    </button>
                    <button className={`btn ${tb.upload} ${active === "Completed" ? tb.active : ""}`} onClick={() => submit("Completed")}>
                      Completed
                    </button>

                    <button className={`btn ${tb.upload} ${active === "Cancelled" ? tb.active : ""}`} onClick={() => submit("Cancelled")}>
                      Cancelled
                    </button>
                    <button className={`btn ${tb.upload} ${active === "No Show" ? tb.active : ""}`} onClick={() => submit("No Show")}>
                      No Show
                    </button>
                  </div>
                </Col>
              </Row>
              <div className={`${tb.dataTable}`}>
                <TableDataGrid bookingdata={bookingdata} />
              </div>
            </Card.Body>
          </Card>
        </div>
      </section>
    </>
  );
}
