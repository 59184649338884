import React from "react";
import m from "../../modal.module.scss";
import st from "../../style.module.scss";
import { NavLink } from "react-router-dom";
import {
  AiFillDelete,
  AiOutlineGlobal,
  AiOutlineInfoCircle,
} from "react-icons/ai";
import cx from "./Model.module.scss";
import tb from "../../datatable.module.scss";
import successIcon from "../../images/successLarge.svg";
import crossRed from "../../images/crossRed.svg";
import infoIcon from "./infoIcon.svg";
import {
  Card,
  Row,
  Col,
  Form,
  Modal,
  Button,
  Tab,
  Nav,
  InputGroup,
} from "react-bootstrap";
import {
  MdClose,
  MdContentCopy,
  MdEdit,
  MdLocationPin,
  MdOutlineCheck,
  MdOutlineFileUpload,
} from "react-icons/md";
import PhoneNumber from "../Phonenumber/Phonenumber";
import { IoMdEyeOff } from "react-icons/io";
import { IoEye } from "react-icons/io5";

const SuccessPasswordModal = (props: any) => {
  let { show, handleSuccessPasswordModalClose, handleClose, message = "", type = 'success', confirmationResponse = null } = props;
  return (
    <>
      <Modal
        centered
        show={show}
        onHide={handleClose || handleSuccessPasswordModalClose}
        className={`${m.modalCts} ${m.resetPopUp}`}
      >
        <Modal.Body>
          {/* <button
            className={`${m.closeIcon}`}
            title="Close"
            onClick={handleSuccessPasswordModalClose}
          >
            <MdClose />
          </button>
          <Col lg={12} className={`${m.title}`}>
            <h5>Success Password</h5>
          </Col> */}
          <div className={`${m.reset_Body}`}>
            <Row>
              <Col md={12} lg={12}>
                <Row>
                  <Col md={12}>
                    <div className={`${m.succes_mainBox}`}>
                      <img src={type === 'success' ? successIcon : type === 'info' ? infoIcon : crossRed} alt="success" />

                      <div className={`${m.succes_content}`}>
                        {!message && <h3>Password Changed!</h3>}
                        <p>{!message ? "Your password has been changed successfully." : message}</p>
                      </div>

                      {type !== "info" && <Col lg={12} className="mt-3 text-center m-auto">
                        <Button className={`${st.btnDisabled} w-auto d-inline-block`} onClick={handleClose || handleSuccessPasswordModalClose}>Ok</Button>
                      </Col>}
                      {type === "info" && (
                        <Row className='justify-content-center'>
                          <Col md={5} className="mt-3">
                            <Button className={`${st.btnDisabled} ${st.cancel} d-inline-block`} style={{minWidth:'auto'}} onClick={handleClose}>Cancel</Button>
                          </Col>
                          <Col md={5} className="mt-3 ">
                            <Button className={`${st.btnDisabled} d-inline-block`} style={{minWidth:'auto'}} onClick={confirmationResponse}>Yes</Button>
                          </Col>

                        </Row>
                      )
                      }
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SuccessPasswordModal;
