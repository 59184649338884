import React from "react";
import cx from "./Sidebar.module.scss";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";

import { MdCategory, MdKeyboardArrowDown, MdListAlt } from "react-icons/md";

// --------- Images --------- //
import student from "../../images/icon-student.svg";

const BusinessMenu = () => {
  return (
    <>
      <div className={`${cx.moreMenuList}`}>
        <h5 className={`${cx.moreMenuTitle}`}>
          <div className={`${cx.menuIcon}`}>
            <img src={student} />
          </div>
          <div className={`${cx.menuName}`}>Business</div>
        </h5>
        <ul>
          <li>
            <NavLink to="/business/business-user">
              <div className={`${cx.menuIcon}`}>
                <MdCategory />
              </div>
              <div className={`${cx.menuName}`}>Business user </div>
            </NavLink>
          </li>
          <li>
            <NavLink to="/business/business-info">
              <div className={`${cx.menuIcon}`}>
                <MdCategory />
              </div>
              <div className={`${cx.menuName}`}>Business Info</div>
            </NavLink>
          </li>
          
          <li>
            <NavLink to="/business/branch">
              <div className={`${cx.menuIcon}`}>
                <MdCategory />
              </div>
              <div className={`${cx.menuName}`}>Branch</div>
            </NavLink>
          </li>
          <li>
            <NavLink to="/business/teams-title">
              <div className={`${cx.menuIcon}`}>
                <MdCategory />
              </div>
              <div className={`${cx.menuName}`}>Team Title</div>
            </NavLink>
          </li>
          <li>
            <NavLink to="/business/teams">
              <div className={`${cx.menuIcon}`}>
                <MdCategory />
              </div>
              <div className={`${cx.menuName}`}>Team</div>
            </NavLink>
          </li>
          <li>
            <NavLink to="/business/services">
              <div className={`${cx.menuIcon}`}>
                <MdCategory />
              </div>
              <div className={`${cx.menuName}`}>Services</div>
            </NavLink>
          </li>
          <li>
            <NavLink to="/business/service-category">
              <div className={`${cx.menuIcon}`}>
                <MdCategory />
              </div>
              <div className={`${cx.menuName}`}>Service Category</div>
            </NavLink>
          </li>
          {/* <li>
            <NavLink to="/business/package">
              <div className={`${cx.menuIcon}`}>
                <MdListAlt />
              </div>
              <div className={`${cx.menuName}`}>Package</div>
            </NavLink>
          </li>
          <li>
            <NavLink to="/business/membership">
              <div className={`${cx.menuIcon}`}>
                <MdCategory />
              </div>
              <div className={`${cx.menuName}`}>Membership</div>
            </NavLink>
          </li>
          <li>
            <NavLink to="/business/amenities">
              <div className={`${cx.menuIcon}`}>
                <MdListAlt />
              </div>
              <div className={`${cx.menuName}`}>Amenities </div>
            </NavLink>
          </li> */}
          <li>
            <NavLink to="/business/client">
              <div className={`${cx.menuIcon}`}>
                <MdListAlt />
              </div>
              <div className={`${cx.menuName}`}>Customer B2B</div>
            </NavLink>
          </li>
          {/* <li>
            <NavLink to="/business/Sales">
              <div className={`${cx.menuIcon}`}>
                <MdListAlt />
              </div>
              <div className={`${cx.menuName}`}>Sales</div>
            </NavLink>
          </li> */}
          {/* <li>
            <NavLink to="/business/Promotion">
              <div className={`${cx.menuIcon}`}>
                <MdListAlt />
              </div>
              <div className={`${cx.menuName}`}>Promotion</div>
            </NavLink>
          </li> */}
          <li>
            <NavLink to="/business/Booking">
              <div className={`${cx.menuIcon}`}>
                <MdListAlt />
              </div>
              <div className={`${cx.menuName}`}>Booking</div>
            </NavLink>
          </li>
          
        </ul>
      </div>
    </>
  );
};

export default BusinessMenu;
