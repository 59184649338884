import React from "react";
import st from "../../style.module.scss";


interface CheckboxProps {
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Checkbox = ({ checked, onChange, register, value }:any) => {
  return (
    <>
      {/* <input type="checkbox" />
      <span className={`${st.checkmark}`}></span> */}
      <div className={`${st.formBox}`}>
        <label className={` ${st.checkbox}`}>
          {/* <input type="checkbox" /> */}
          <input type="checkbox" checked={checked} value={value} onChange={onChange} {...register}/>
          <span className={`${st.checkmark}`}></span>&nbsp;
        </label>
      </div>
    </>
  );
};

export default Checkbox;

