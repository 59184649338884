import React, { useEffect, useState } from "react";
import m from "../../modal.module.scss";
import pm from "./helpdocument.module.scss";
import st from "../../style.module.scss";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Accordion, Row, Col, Modal, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { MdEdit, MdSave } from "react-icons/md";
import { httpRequest } from "../../Apis/commonApis";


const AddHelpDocumentModel = (props: any) => {
  let { show, handleClose, fetchHelpDocuments, document, data } = props;

  const [error, setError] = useState("");
  // const [catagoryData, setCatagoryData] = useState<any>({});
  const [selectedCategories, setSelectedCategories] = useState<any[]>([]);
  const [briefAbout, setBriefAbout] = useState<any>("");
 
  const [isEditing, setIsEditing] = useState(false);

  const { handleSubmit, formState: { errors }, reset } = useForm();

  console.log(data , "datasdfsf");

  useEffect(() =>{
    if(data){
      setBriefAbout(data?.details)
    }
  },[data])
  

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["bold", "italic", "underline"],
      ["link"],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      ["clean"],
      // ['image'],
    ],


  };

  const handleAbout = (newContent: string) => {
    setBriefAbout(newContent);
  };

  const onSubmit = async () => {
    try {
      console.log("Updating document...");
      const updatedData = {
        ...document,
        details: briefAbout
      };
  
      const res = await httpRequest(`update_helpDocuments/${props?.data?.id}`, "PATCH", updatedData, "application/json");
      if (res?.status) {
        setBriefAbout(res?.data?.details)
        console.log(res?.data,"res")
        setTimeout(function() {
          handleClose();
        }, 1000);
        fetchHelpDocuments();
        // handleClose();
      } else {
        setError(res.message);
      }
    } catch (error) {
      console.error("Error updating document:", error);
      setError("Error updating document");
    }
  }
  

  return (
    <Modal
      size="lg"
      centered
      show={show}
      onHide={handleClose}
      className={`${m.modalCts}`}
    >
      

      <Modal.Header closeButton  className={`${m.modalHeading_close} align-items-start`}>
        <Modal.Title className={`${m.modalTitle} ${m.modalTitleLarge}`}>
          {/* <span>Edit Help Document</span> */}
          {/* <span className={`${m.modalTitleDetails}`}> */}
          <span style={{ fontSize: '0.4rem' }}>
            <Modal.Title className={pm.grayText}>
              <span style={{ display: 'flex', alignItems: 'center', }}>
                Screen No: {props.data?.screenNumber}
              </span>
            </Modal.Title> <span style={{ fontSize: '0.9rem' }}>Main Menu: {props.data?.mainMenu}  &nbsp;  Side Menu: {props.data?.sideMenu}   &nbsp;  Tab: {props.data?.tab}  &nbsp; Change/New: {props.data?.changeStatus}  &nbsp; {/* Add space */} SequenceNo:{props?.data?.sequence}</span>
          </span>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ padding: '38px 20px', paddingTop: '10px' }}>
        {/* <Modal.Body style={{ padding: '38px 20px 10px' }}> */}
        <Row className="w-100 mx-auto">
          <Col md={12}>
            <div className={`${st.formBox} ${pm.editorBox}`}>
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <label htmlFor="Details">Details</label>
                  </Accordion.Header>
                  <Accordion.Body className="p-0">
                    <div className={`${pm.editorContainer}`}>
                      {/* <ReactQuill
                        value={briefAbout}
                        onChange={handleAbout}
                        modules={modules}
                      // readOnly={!isEditing}
                      /> */}
                      {/* {catagoryData ? ( */}
                      <div className={`${pm.editorContainer}`}>
                        <ReactQuill
                          value={briefAbout}
                          onChange={handleAbout}
                          modules={modules}
                        />
                      </div>
                    {/* ) : null } */}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>

            {/* <div className="d-flex justify-content-end mt-3">
              <Button
                variant="primary"
                onClick={isEditing ? handleSubmit(addServiceTag) : () => setIsEditing(true)}
              >
                {isEditing ? <MdSave /> : <MdEdit />} {isEditing ? "Save" : "Edit"}
              </Button>
            </div> */}

            <Col lg={12} className="mt-3">
              <Button
                className={`${st.btnDisabled}`}
              // onClick={isEditing ? handleSubmit(addServiceTag) : () => setIsEditing(true)}
              onClick={handleSubmit(onSubmit)}
              >
                {/* {isEditing ? <MdSave /> : <MdEdit />} {isEditing ? "Save" : "Edit"} */}
                Save
              </Button>
            </Col>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default AddHelpDocumentModel;
