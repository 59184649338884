import React, { useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import tb from "../../../datatable.module.scss";
import { NavLink } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import Checkbox from "../../../components/Forms/Checkbox";
import st from "../../../style.module.scss";

import category1 from "../../../images/Chair.svg";
import Status from "../../../components/Forms/Status";
import AmenitiesM from "../../../components/Modals/Amenities";
import DeletePopup from "../../../components/Modals/DeletePopup";
import ImageManagementM from "../../../components/Modals/ImageManagementModal";
import { httpRequest } from "../../../Apis/commonApis";

const StatusButton = (props: any) => {
  let { params, getCollection } = props;
  const navigate = useNavigate()

  const [showDelete, setDeleteShow] = useState(false);
  const handleDeleteClose = () => setDeleteShow(false);
  const handleDeleteShow = () => setDeleteShow(true);

  const deletePackages = async () => {
    const res = await httpRequest(`collection_cms/${params?.row?.id}`, "DELETE", null, "withoutToken");
    if (res?.status == true) {
      getCollection && getCollection()
      setTimeout(() => {
        handleDeleteClose()
      }, 1000)
    } else {
    }
  };


  return (
    <>
      <div>
        <ul className={`${tb.actionTable}`}>
          <li>
            <NavLink
              className={`btn ${tb.edit}`}
              title="Edit"
              to={"edit-collection"}
              state={{collectionId:params?.row?.id}}
              
            >
              <MdEdit />
            </NavLink>
          </li>
          <li>
            <NavLink
              className={`btn ${tb.delete}`}
              title="Delete"
              to="#"
              onClick={handleDeleteShow}
            >
              <AiFillDelete />
            </NavLink>
          </li>
          {/* <li>
          <Status status={params.row.isActive} api="collection_cms" id={params?.row?.id}
              apiMethod="formdata" getLatest={getCollection} />
          </li> */}
        </ul>
      </div>

      <DeletePopup show={showDelete} handleDeleteClose={handleDeleteClose} deletePackages={deletePackages}/>
    </>
  );
};



export default function CollectionGrid({ collectionList, getCollection }: any) {

  const columns = [
    { field: "srNumber", headerName: "Sr No", flex: 1, minWidth: 60 },
    {
      field: "collectionName",
      headerName: "Collection Name",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "collectionType",
      headerName: "Collection Type",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "url",
      headerName: "URL",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "sticker",
      headerName: "Sticker",
      flex: 1,
      minWidth: 150,
    },

    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 280,
      renderCell: (params: any) => <StatusButton params={params} getCollection={getCollection} />,
    },
  ];

  let rows: any = [];

  rows = collectionList?.map((item: any, i: number) => {
    return (
      {
        srNumber: i + 1,
        id: item?._id,
        collectionName: item?.collectionName,
        collectionType: item?.collectionType,
        url: item?.url || "-",
        sticker: item?.sticker || "-",
        isActive:item?.isActive,
        action: "",
      }
    )
  })



  return (
    <div style={{ width: "100%" }}>
      <DataGrid
        columns={columns}
        rows={rows}
        //onRowClick={handleRowClick}
        autoHeight
        hideFooterPagination={true}
        rowHeight={48}
        headerHeight={48}
        checkboxSelection
        disableSelectionOnClick
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      />
    </div>
  );
}
