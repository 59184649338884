import React, { useEffect, useState } from "react";
import cx from "./DataShow.module.scss";
import pm from "../../../newStyle.module.scss";
import st from "../../../style.module.scss";
import { useForm, Controller } from "react-hook-form";
import { useLocation, useNavigate } from 'react-router-dom';
import { Accordion, Col, Nav, Row, Tab } from "react-bootstrap";
import basic from "../../../images/basicInfoTab.svg";
import editIcon from "../../../images/editIcon.svg";
import { httpRequest } from "../../../Apis/commonApis";
import Select from "react-select";
import Checkbox from "../../../components/Forms/Checkbox";
import { FiMinus } from "react-icons/fi";
import MultiSelect from "../../../components/MultipleSelect/MultipleSelect";

const AddData = (props: any) => {
  const [catagoryData, setCatagoryData] = useState<any>([]);
  const [business, setBusiness] = useState<any>([]);
  const [selectedSort, setSelectedSort] = useState<any>([]);
  const [actionArray, setActionArray] = useState<any>([{ sq: 1, businessId: "", businessAccountNo: "", error: false }])


  const getBusinessInfo = async () => {
    const res: any = await httpRequest("get_BusinessInfo", "get", null, "withoutToken");
    if (res?.status == true) {
      setBusiness(res?.data);
    }
  };

  useEffect(() => {
    getCatagory();
    getBusinessInfo();
  }, []);


  const getCatagory = async () => {
    const res = await httpRequest("get_Catagory", "get", null, null);
    if (res.status == true) {
      setCatagoryData(res?.data);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    watch,
  } = useForm();

  const navigate = useNavigate();
  const location = useLocation();

  const [currentId, setCurrentId] = useState(location?.state);
  const [count, setCount] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [collectionArray, setCollectionArray] = useState<any>([]);

  const [newWeb, setNewweb] = useState<any>([])
  const [errorTrig, setErrorTrig] = useState<any>(false)

  const [webpage, setWebPage] = useState<any>({})
  const [collectionList, setCollectionList] = useState([])


  async function getCollection() {
    const res = await httpRequest(`collection_cms`, "GET", null, "json");
    if (res.status) {
      setCollectionList(res?.data)
    }
  }

  useEffect(() => {
    getCollection()
  }, [])

  const filterCategory = catagoryData?.filter((cat: any) => cat?._id === webpage?.businessCategoryId?._id ? true : !newWeb?.some((item: any) => item?.businessCategoryId?._id === cat?._id))


  const fetchHelpDocuments = async () => {
    const res = await httpRequest(`get_categorySearch`, "GET", null, null);
    if (res.status === true) {
      setWebPage(res?.data?.find((item: any) => item?._id === location?.state?.pageId))
      // setNewweb
      setNewweb(res?.data);
    } else {
      console.error("Failed to fetch help documents", res);
    }
  };

  useEffect(() => {
    fetchHelpDocuments()
  }, [location?.state])

  const [filterBusiness, setFilterBusiness] = useState<any>([])

  useEffect(() => {
    if (watch("category") && business?.length > 0) {
      const filterBusiness = business?.filter((item: any) => item?.businessCatagoryId?.some((cat: any) => cat?._id === watch("category")));
      setFilterBusiness(filterBusiness)
    }
  }, [watch("category"), business, webpage])

  useEffect(() => {
    if (webpage) {
      setValue("category", webpage?.businessCategoryId?._id);
      setValue("isCollection", webpage?.isCollection)
      setValue("nearMe", webpage?.nearMe)
      setSelectedSort(webpage?.sort || [])
      setCollectionArray(webpage?.collectionArr?.length > 0 ? webpage?.collectionArr : [{ sq: 1, collectionId: "", error: false }])
      setActionArray(webpage?.action?.length > 0 ? webpage?.action : [{ sq: 1, businessId: "", businessAccountNo: "", error: false }])
    }
  }, [webpage]);


  const onSubmit = async (data: any) => {
    if (!data.category) {
      setErrorMessage("this field is required.");
      return;
    }

    const actionArr = actionArray?.filter((item: any) => item?.businessId !== "")
    const collectionArr = collectionArray?.filter((item: any) => item?.collectionId !== "")

    if(actionArr?.some((item:any) => item?.error)){
      setErrorTrig(true)
      return
    }
    if(collectionArr?.some((item:any) => item?.error)){
      setErrorTrig(true)
      return
    }

    setErrorMessage("");
   
    const payload = {
      businessCategoryId: data?.category,
      assignBusinessId: data?.assignBusiness?.map((item: any) => item?.value),
      sort:selectedSort,
      collectionArr: data?.isCollection ? collectionArr?.length > 0 ? collectionArr : [] : [],
      action:actionArr,
      isCollection:  data?.isCollection,
      nearMe: data?.nearMe,
    };

    let res;
    if (location?.state?.pageId) {
      res = await httpRequest(`update_categorySearch/${location?.state?.pageId}`, "PATCH", payload, "application/json");
    } else {
      res = await httpRequest("create_categorySearch", "POST", payload, "application/json");
    }

    if (res.status === true) {
      setErrorMessage("");
      navigate('/settings/datashow');
      if (!currentId) {
        setCount((prev) => prev + 1);
      }
      reset();
      setCurrentId(null);
    } else {
      setErrorMessage(res?.message);
      setTimeout(() => {
        setErrorMessage("");
      }, 2000);
    }
  };


  const priorityList = [
    { label: "Name", value: "name" },
    { label: "Location", value: "location" },
    { label: "Priority", value: "priority" },
    { label: "Featured", value: "featured" },
    { label: "Trending", value: "trending" },
  ]
  const isCollection = watch("isCollection")

  useEffect(() => {
    if ((!webpage || isCollection !== webpage?.isCollection) && isCollection) {
      setCollectionArray(([{ sq: 1, collectionId: "", error: false }]))
    }
  }, [isCollection])

  function handleActionArray(index: number, value: any, type: string = "",) {
    let newArray = type === "collectionId" ? [...collectionArray] : [...actionArray];
    const duplicateValue: any = newArray?.some((item: any) => item?.[type] === value);
    if (type === "businessId") {
      newArray[index] = {
        ...newArray[index],
        [type]: value,
        error: duplicateValue ? true : false,
        businessAccountNo: business?.find((item: any) => item?._id === value)?.businessAccountNo
      }
      setActionArray(newArray)
      
    } else {
      newArray[index] = {
        ...newArray[index],
        error: duplicateValue ? true : false,
        [type]: value
      }
      setCollectionArray(newArray)
    }
  }  
  
  function handleAddRemoveHandler(type: string, index: number, actionType: string = "") {
    let newArray = actionType === "collection" ? [...collectionArray] :[...actionArray]
    if (type === "inc") {
      if (actionType === "business") {
        newArray.push({ sq: actionArray?.length + 1, businessId: "", businessAccountNo: "", error: false })
        setActionArray(newArray)
      }
      else if (actionType === "collection") {
        newArray.push({ sq: collectionArray?.length + 1, collectionId: "" })
        setCollectionArray(newArray)
      }
    } else {
      newArray = newArray.filter((_: any, i: any) => i !== index)
    }
    if (actionType === "collection") {
      setCollectionArray(newArray)
    } else {
      setActionArray(newArray)
    }
  }


  return (
    <section
      className={`${pm.pageWrapper}`}
      style={{ overflowY: "auto", height: "100%", paddingBottom: "80px" }}
    >
      <div className={`${pm.pageTitle}`}>
        <div className={`${pm.titleInfo}`}>
          {/* Add Category Web Pages */}
          {currentId ? "Edit Category Web Pages" : "Add Category Web Pages"}
        </div>
        <div className={`${pm.rightInfo}`}></div>
      </div>

      <div className={`${pm.tabbingBox}`}>
        <Tab.Container id="left-tabs-example" defaultActiveKey="tabNew1">
          <Nav variant="pills">
            <Nav.Item>
              <Nav.Link eventKey="tabNew1">
                <img src={basic} alt="info" className={`${pm.default}`} />
                Details
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="tabNew1">
              <Row>
                <Col xl={3} lg={4} md={6} className="px-2">
                  <div className={cx.price_categorySelect}>
                    <div className={`${pm.formBox}`}>
                      <label className="form-label">Category</label>
                      <select
                        className="form-select"
                        {...register("category", { required: true })}
                        disabled={webpage}
                      >
                        <option value="">Select Category</option>
                        {filterCategory?.map((category: any, index: any) => (
                          <option key={index} value={category?._id}   >{category?.BusinessCategoryName}</option>
                        ))}
                      </select>
                      {errorMessage && <p className="errorMessage">{errorMessage}</p>}
                      {/* {errors?.category.type === "required" && <p className="errorMessage">{errorMessage}</p>} */}
                      {/* {errorTrig && <p className="errorMessage">{"please select all fields"}</p>} */}
                    </div>
                  </div>
                </Col>

                <Col xl={3} lg={4} md={6} className="px-2">
                  <div>
                    <ul className={`${cx.additional_checkBox}`}>
                      <li>
                        <label>Collection</label>
                        <Checkbox register={{ ...register("isCollection") }} />
                      </li>
                      <li>
                        <label>Near Me</label>
                        <Checkbox register={{ ...register("nearMe") }} />
                      </li>
                    </ul>
                  </div>
                </Col>

                <Col xl={2} lg={4} md={6} className="px-2">
                  <div className={cx.price_categorySelect}>
                    <button className="btn plusBtn" style={{ width: '120px' }} onClick={handleSubmit(onSubmit)}>
                      Save
                    </button>
                  </div>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col md={12} lg={10}>
                  <div className={`${cx.addAccordiaon_features}`}>
                    <Accordion>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>Sorting Order </Accordion.Header>
                        <Accordion.Body>
                          <Row>
                            <Col md={4}>
                              <div className={pm.formBox}>
                                <label className="form-label">Business Priority</label>
                                <MultiSelect list={priorityList} value={selectedSort} onChange={(data: any) => setSelectedSort(data)} />
                              </div>
                            </Col>
                          </Row>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col md={12} lg={10}>
                  <div className={`${cx.addAccordiaon_features}`}>
                    <Accordion>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>Select Business</Accordion.Header>
                        <Accordion.Body>
                          <div>
                            <Row>
                              <Col lg={2} md={3} className="px-2">
                                <div className={cx.price_categorySelect}>
                                  <div className={`${pm.formBox} mb-0`}>
                                    <label className="form-label">Sequence Number</label>
                                  </div>
                                </div>
                              </Col>
                              <Col lg={6} md={6} className="px-2">
                                <Col lg={11}>
                                  <div className={cx.price_categorySelect}>
                                    <div className={`${pm.formBox} mb-0`}>
                                      <label className="form-label">Assign Business</label>
                                    </div>
                                  </div>
                                </Col>
                              </Col>
                              <Col lg={2} md={3} className="px-2">
                                <div className={cx.price_categorySelect}>
                                  <div className={pm.formBox}>
                                    <label className="form-label">Business ID number</label>
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            {actionArray?.map((item: any, i: number) => {
                              return (
                                <Row className="align-items-center" key={i}>
                                  <Col lg={2} md={3} className="px-2">
                                    <div className={cx.price_categorySelect}>
                                      <div className={pm.formBox}>
                                        <label className="form-label">{item?.sq}</label>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col lg={6} md={6} className="px-2">
                                    <Col lg={11}>
                                      <div className={cx.price_categorySelect}>
                                        <div className={pm.formBox} >
                                          <select
                                            className="form-select"
                                            value={item?.businessId}
                                            onChange={(e: any) => handleActionArray(i, e.target.value, "businessId")}
                                            disabled={filterBusiness?.length === 0}
                                            style={errorTrig && (item?.error) ? { border: "1px solid red" } : {}}
                                          >
                                            <option value="">{filterBusiness?.length > 0 ?  "Select Business" : "no business found"}</option>
                                            {filterBusiness?.map((business: any) => {
                                              return (
                                                <option value={business?._id} key={business?._id}>{business?.businessName}</option>
                                              )
                                            })}
                                          </select>

                                        </div>
                                      </div>
                                    </Col>
                                  </Col>
                                  <Col lg={3} md={3} className="px-2">
                                    <div className={cx.price_categorySelect}>
                                      <div className={pm.formBox}>
                                        <div>{item?.businessAccountNo}</div>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col >
                                    <button className={cx.addBtn} onClick={() => handleAddRemoveHandler(i === 0 ? "inc" : "dec", i, "business")}>{i === 0 ? "+" : "-"}</button>
                                  </Col>
                                </Row>
                              )
                            })}
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </Col>
              </Row>
              {isCollection && <>
                <Row className="mt-3">
                  <Col md={12} lg={10}>
                    <div className={`${cx.addAccordiaon_features}`}>
                      <Accordion>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>Select Collections</Accordion.Header>
                          <Accordion.Body>
                            <div>
                              <Row>
                                <Col lg={2} md={3} className="px-2">
                                  <div className={cx.price_categorySelect}>
                                    <div className={`${pm.formBox} mb-0`}>
                                      <label className="form-label">Sequence Number</label>
                                    </div>
                                  </div>
                                </Col>
                                <Col lg={6} md={6} className="px-2">
                                  <Col lg={11}>
                                    <div className={cx.price_categorySelect}>
                                      <div className={`${pm.formBox} mb-0`}>
                                        <label className="form-label">Assign Collection</label>
                                      </div>
                                    </div>
                                  </Col>
                                </Col>

                              </Row>

                              {collectionArray?.map((item: any, i: number) => {
                                return (
                                  <Row className="align-items-center" key={i}>
                                    <Col lg={2} md={3} className="px-2">
                                      <div className={cx.price_categorySelect}>
                                        <div className={pm.formBox}>
                                          <label className="form-label">{item?.sq}</label>
                                        </div>
                                      </div>
                                    </Col>
                                    <Col lg={6} md={6} className="px-2">
                                      <Col lg={11}>
                                        <div className={cx.price_categorySelect}>
                                          <div className={pm.formBox} >
                                            <select
                                              className="form-select"
                                              value={item?.collectionId}
                                              onChange={(e: any) => handleActionArray(i, e.target.value, "collectionId")}
                                              disabled={collectionList?.length === 0}
                                              style={errorTrig && (item?.error) ? { border: "1px solid red" } : {}}
                                            >
                                              <option value="">{collectionList?.length > 0 ? "Select Collection": "no collection found"}</option>
                                              {collectionList?.map((collection: any) => {
                                                return (
                                                  <option value={collection?._id} key={collection?._id}>{collection?.collectionName}</option>
                                                )
                                              })}
                                            </select>

                                          </div>
                                        </div>
                                      </Col>
                                    </Col>

                                    <Col >
                                      <button className={cx.addBtn} onClick={() => handleAddRemoveHandler(i === 0 ? "inc" : "dec", i, "collection")}>{i === 0 ? "+" : "-"}</button>
                                    </Col>
                                  </Row>
                                )
                              })}
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </Col>
                </Row>
              </>}
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div >
    </section >
  );
};

export default AddData;
