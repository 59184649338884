import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import pm from "../../../newStyle.module.scss";
import st from "../../../style.module.scss";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from 'react-router-dom';
import { httpRequest } from "../../../Apis/commonApis";
import { Tab, Nav, Row, Col, Accordion } from "react-bootstrap";
import basic from "../../../images/basicInfoTab.svg";
import Checkbox from "../../../components/Forms/Checkbox";
import MultiSelect from "../../../components/MultipleSelect/MultipleSelect";
import uploadFile from "../../../images/iconUpload.svg";
import uploadIcon from "../../../images/uploadIcon.svg";
import SuccessPasswordModal from "../../../components/Modals/SuccessPasswordModal";

const AddCollection = (props: any) => {
  const { register, handleSubmit, formState: { errors }, setValue, reset, watch } = useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const [icon, setIcon] = React.useState<any>();
  const [businessCat, setBusinessCat] = useState([]);
  const [loading, setLoading] = useState<any>(false);

  const [selectedCategories, setSelectedCategories] = useState<any>([])

  const [message, setMessage] = useState<String>("")
  const [show, setShow] = useState<boolean>(false)
  const handleClose = () => {
    setMessage("")
    setShow(false)
  }

  const [actionArray, setActionArray] = useState<any>([]);

  const [imageagain, setImageAgain] = React.useState<any>(null);
  const [businessData, setBusinessData] = useState<any>([]);
  const [promotionList, setPromotionList] = useState<any>([]);
  const [serviceTagList, setServiceTagList] = useState<any>([]);
  const [selectedTag, setSelectedTag] = useState<any>([]);
  const [selectedPromotion, setSelectedPromotion] = useState<any>([]);

  const [attribute1List, setAttribute1List] = useState<any>([])
  const [attribute2List, setAttribute2List] = useState<any>([])
  const [attribute3List, setAttribute3List] = useState<any>([])

  const [selectedAttribute1, setSelectedAttribute1] = useState<any>([]);
  const [selectedAttribute2, setSelectedAttribute2] = useState<any>([]);
  const [selectedAttribute3, setSelectedAttribute3] = useState<any>([]);

  const [errorTrig, setErrorTrig] = useState<any>(false)

  const [editData, setEditData] = useState<any>(null)

  async function getCms() {
    const res = await httpRequest(`collection_cms?_id=${location?.state?.collectionId}`, "GET", null, "json");
    if (res.status) {
      setEditData(res?.data[0])
    }
  }
  
  useEffect(() => {
    if (editData) {
      setValue("collectionType", editData?.collectionType)
      setValue("collectionName", editData?.collectionName)
      setValue("url", editData?.url || "")
      setValue("sticker", editData?.sticker || "");

      const filterObj = editData?.filter?.[0]
      if (filterObj?.attribute1) {
        setSelectedAttribute1(filterObj?.attribute1)
      }

      if (filterObj?.attribute2) {
        setSelectedAttribute2(filterObj?.attribute2)
      }
      if (filterObj?.attribute3) {
        setSelectedAttribute3(filterObj?.attribute3)
      }
      if (filterObj?.tag) {
        setSelectedTag(filterObj?.tag)
      }
      if (filterObj?.category) {
        setSelectedCategories(filterObj?.category)
      }
      if (filterObj?.priority) {
        setValue("priority", filterObj?.priority)
      }


      if (filterObj?.featured) {
        setValue("feature", filterObj?.featured)
      }
      if (filterObj?.trending) {
        setValue("trending", filterObj?.trending)
      }

      if (editData?.sort?.[0]) {
        setValue("sort", editData?.sort?.[0])
      }

      if (editData?.collectionType === "promotion") {
        setSelectedPromotion(editData?.promotion)
      }

      if (editData?.action?.length > 0) {
        setActionArray(editData?.action?.map((item: any) => ({ ...item, error: false })))
      } else {
        setActionArray([{ sq: 1, businessId: "", businessAccountNo: "", error: false }])
      }

      if (editData?.image) {
        setIcon(editData?.image)
      }
    }
  }, [editData, businessData])


  const getBusinessInfo = async () => {
    const res = await httpRequest("get_BusinessInfo", "get", null, "withoutToken");
    if (res?.status == true) {
      setBusinessData(res?.data)
    }
  };


  const getServiceTag = async () => {
    let res = await httpRequest(`get_ServiceTag`, "get", null, "auth");
    if (res?.status) {
      setServiceTagList(res.data)
    }
  }

  useEffect(() => {
    if (location?.state?.collectionId) {
      getCms()
    }
  }, [location?.state?.collectionId])

  const getbusinessCatagory = async () => {
    const res: any = await httpRequest("get_Catagory", "Get", null, "withoutToken");
    if (res?.status == true) {
      setBusinessCat(res?.data)
    }
  };

  const getPromotion = async () => {
    const res: any = await httpRequest(`getPromotion`, "get", null, null);

    if (res?.status) {
      setPromotionList(res?.data)
    }
  }

  async function getAttribute1() {
    const res = await httpRequest(`attribute1`, "GET", null, "json");
    if (res.status) {
      setAttribute1List(res?.data)
    }
  }

  async function getAttribute2() {
    const res = await httpRequest(`attribute2`, "GET", null, "json");
    if (res.status) {
      setAttribute2List(res?.data)
    }
  }

  async function getAttribute3() {
    const res = await httpRequest(`attribute3`, "GET", null, "json");
    if (res.status) {
      setAttribute3List(res?.data)
    }
  }

  useEffect(() => {
    getbusinessCatagory()
    getBusinessInfo()
    getPromotion()
    getServiceTag()

    getAttribute1()
    getAttribute2()
    getAttribute3()
  }, [])

  const collectionType = watch("collectionType");
  const priority = watch("priority");
  const featured = watch("feature");
  const trending = watch("trending");

  const handleImageChange = function (e: React.ChangeEvent<HTMLInputElement>) {
    const fileList: any = e.target.files;
    if (!fileList) return;
    let display = URL.createObjectURL(fileList[0]);
    setIcon(display);
    setImageAgain(fileList[0]);
  };


  const filterObj = {
    category: selectedCategories,
    tag: selectedTag,
    attribute1: selectedAttribute1,
    attribute2: selectedAttribute2,
    attribute3: selectedAttribute3,
    priority,
    featured,
    trending
  }

  async function submitHandler(data: any) {
    const formdata = new FormData();
    formdata.append("collectionType", data?.collectionType)
    formdata.append("collectionName", data?.collectionName)
    formdata.append("sticker", data?.sticker);
    formdata.append("isActive", JSON.stringify(editData ? editData?.isActive : true));

    formdata.append("filter", JSON.stringify([filterObj]));
    formdata.append("sort", JSON.stringify([data?.sort]));

    if (collectionType === "promotion") {
      formdata.append("promotion", JSON.stringify(selectedPromotion));
    }

    if (data?.url) {
      formdata.append("url", data?.url)
    }

    if (imageagain) {
      formdata.append("image", imageagain)
    }

    if (collectionType === "promotion" && selectedPromotion?.length === 0) {
      setErrorTrig(true)
      setTimeout(() => {
        setErrorTrig(false)
      }, 3000)
      return
    }

    if (actionArray?.some((item: any) => item?.error)) {
      setErrorTrig(true)
      setTimeout(() => {
        setErrorTrig(false)
      }, 3000)
      return
    }

    const actionArr = actionArray?.filter((item: any) => item?.businessId !== "")

    formdata.append("action", JSON.stringify(actionArr?.length > 0 ? actionArr?.map((item: any) => ({ sq: item?.sq, businessId: item?.businessId, businessAccountNo: item?.businessAccountNo, })) : []))

    setLoading(true)
    const path = editData ? `collection_cms/${editData?._id}` : "collection_cms";
    const method = editData ? "PATCH" : "POST"
    const res = await httpRequest(path, method, formdata, "formdata");

    if (res.status) {
      setShow(true)
      setMessage(res?.message)
      setTimeout(() => {
        navigate("/webpages/collections")
      }, 2000)
    }
    setLoading(false)

  }

  useEffect(() => {
    console.log(collectionType , "collectionType");
    
    if (!editData && (editData?.collectionType !== collectionType)) {
      setActionArray([{ sq: 1, businessId: "", businessAccountNo: "", error: false }])
    }
  }, [collectionType]);

  const filterBusiness = businessData?.filter((item: any) => {
    const catCheck = item?.businessCatagoryId?.some((cat: any) => selectedCategories?.length > 0 ? selectedCategories?.some((selCat: any) => selCat?.value === cat?._id) : true);
    const trendingCheck = trending ? item?.trending === trending : true
    const featureCheck = featured ? item?.featured === featured : true
    const priorityCheck = priority ? item?.priority === Number.parseInt(priority) : true;
    
    const tagCheck = selectedTag?.length > 0 ? item?.searchArray?.some((item: any) => selectedTag.some((tag: any) => tag?.label?.toLocaleLowerCase()?.replaceAll(" ", "") === item?.toLocaleLowerCase()?.replaceAll(" ", ""))) : true;
    const attribute1Check = selectedAttribute1?.length > 0 ? selectedAttribute1?.some((attr: any) => item?.attribute1?.some((data:any) => data ? data?._id === attr?.value : false)) : true
    const attribute2Check = selectedAttribute2?.length > 0 ? selectedAttribute2?.some((attr: any) => item?.attribute2?.some((data:any) => data ? data?._id === attr?.value : false)) : true
    const attribute3Check = selectedAttribute3?.length > 0 ? selectedAttribute3?.some((attr: any) => item?.attribute3?.some((data:any) => data ? data?._id === attr?.value : false)) : true
    
    return catCheck && trendingCheck && featureCheck && priorityCheck && attribute1Check && attribute2Check && attribute3Check && tagCheck
  })

  useEffect(() => {
    if (actionArray?.length > 0) {
      const modifiedArr = actionArray?.map((item: any) => {
        if (filterBusiness?.some((business: any) => business?._id === item?.businessId)) {
          return item;
        } else {
          return { ...item, businessId: "", businessAccountNo: "" };
        }
      });

      const isModifiedArrDifferent = modifiedArr.some((item: any, index: number) => item.businessId !== actionArray[index].businessId || item.businessAccountNo !== actionArray[index].businessAccountNo);
      if (isModifiedArrDifferent) {
        setActionArray(modifiedArr);


      }
    }
  }, [filterBusiness]);


  function handleAddRemoveHandler(type: string, index: number, actionType: string = "") {
    let newArray = [...actionArray]
    if (type === "inc") {
      if (actionType === "business") {
        newArray.push({ sq: actionArray?.length + 1, businessId: "", businessAccountNo: "", error: false })
      } else {
        newArray.push({ sq: actionArray?.length + 1, promotionId: "", error: false })
      }
      setActionArray(newArray)
    } else {
      newArray = newArray.filter((_: any, i: any) => i !== index)
    }
    setActionArray(newArray)
  }

  function handleAddCat(index: number, value: any, type: string = "",) {
    let newArray = [...actionArray];
    const duplicateValue: any = newArray.some((item: any) => item?.[type] === value);
    if (type === "businessId") {
      newArray[index] = {
        ...newArray[index],
        [type]: value,
        error: duplicateValue ? true : false,
        businessAccountNo: businessData?.find((item: any) => item?._id === value)?.businessAccountNo
      }
    } else {
      newArray[index] = {
        ...newArray[index],
        error: duplicateValue ? true : false,
        [type]: value
      }
    }

    setActionArray(newArray)
  }

  function handleCategory(data: any, type: any) {
    if (type === "category") {
      setSelectedCategories(data)
    }

    if (type === "tag") {
      setSelectedTag(data)
    }
    if (type === "attribute1") {
      setSelectedAttribute1(data)
    }
    if (type === "attribute2") {
      setSelectedAttribute2(data)
    }
    if (type === "attribute3") {
      setSelectedAttribute3(data)
    }
  }


  return (
    <>
      <SuccessPasswordModal handleClose={handleClose} show={show} message={message} />

      <section className={`${pm.pageWrapper}`} style={{ overflowY: "auto", height: "100%", paddingBottom: "80px" }}>
        <div className={`${pm.pageTitle}`}>
          <div className={`${pm.titleInfo}`}>
            {editData ? editData?.collectionName : "add collection"}
          </div>
          <div className={`${pm.rightInfo}`}></div>
        </div>

        <div className={`${pm.tabbingBox}`}>
          <Tab.Container id="left-tabs-example" defaultActiveKey="tabNew1">
            <Nav variant="pills">
              <Nav.Item>
                <Nav.Link eventKey="tabNew1">
                  <img src={basic} alt="info" className={`${pm.default}`} />
                  Details
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="tabNew1">
                <Row>
                  <Col xl={3} lg={4} md={6}>
                    <div className={cx.price_categorySelect}>
                      <div className={pm.formBox}>
                        <label className="form-label">Collection Name</label>
                        <input type="text" className="form-control" placeholder="Collection Name" {...register("collectionName", { required: true })} />
                        {errors?.collectionName?.type === "required" && (
                          <p className={"errorMessage"}>This field is required</p>
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col xl={3} lg={4} md={6}>
                    <div className={cx.price_categorySelect}>
                      <div className={pm.formBox}>
                        <label className="form-label">Collection Type </label>
                        <select className="form-select" {...register("collectionType", { required: true })}>
                          <option value="">Collection Type</option>
                          <option value="business">Business</option>
                          <option value="promotion">Promotion</option>
                        </select>
                        {errors?.collectionType?.type === "required" && (
                          <p className={"errorMessage"}>This field is required</p>
                        )}
                      </div>
                    </div>
                  </Col>

                  <Col xl={2} lg={4} md={4} className="px-2 mt-4">
                    <div className={cx.saveButton}>
                      <button className="btn plusBtn" onClick={handleSubmit(submitHandler)}>
                        {loading ? (
                          <div
                            className="spinner-border spinner-border-sm text-light"
                            role="status"
                          ></div>
                        ) : (
                          editData ? "Update" : 'Save'
                        )}
                      </button>
                    </div>
                  </Col>
                </Row>

                <Row className="align-items-end">
                  <Col xl={3} lg={2} md={3} >
                    <div className={cx.price_categorySelect}>
                      <div className={`${pm.formBox} position-relative`}>
                        <label className="form-label" htmlFor="url">URL</label>
                        <input type="text" id="url" className="form-control" placeholder="URL" {...register("url", { required: true })} />
                        {errors?.url?.type === "required" && (
                          <p className={"errorMessage position-absolute b-0"}>This field is required</p>
                        )}
                      </div>
                    </div>
                  </Col>

                  <Col xl={3} lg={4} md={6} >
                    <div className={`${pm.formBox} position-relative`}>
                      <label className="form-label">Sticker</label>
                      <input type="text" className="form-control" placeholder="Sticker" {...register("sticker")} />
                    </div>
                  </Col>

                  {collectionType === "promotion" && (
                    <Col md={3}>
                      <div className={`${pm.formBox} position-relative`}>
                        <label className="form-label">Promotion Name</label>
                        <MultiSelect list={promotionList?.map((item: any) => ({ label: item?.promotionName, value: item?._id }))} value={selectedPromotion} onChange={(data: any) => setSelectedPromotion(data)} />
                        {collectionType === "promotion" && selectedPromotion?.length === 0 && errorTrig && (
                          <p className={"errorMessage position-absolute b-0"}>This field is required</p>
                        )}
                      </div>
                    </Col>

                  )}
                </Row>
                <Row className="mt-2">
                  <Col md={12} lg={10}>
                    <div>
                      <Accordion className="mb-3">
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>Business Filters</Accordion.Header>
                          <Accordion.Body>
                            <Row>
                              <Col md={4}>
                                <div className={pm.formBox}>
                                  <label className="form-label">Categories </label>
                                  <MultiSelect list={businessCat?.map((item: any) => ({ label: item?.BusinessCategoryName, value: item?._id }))} value={selectedCategories} onChange={(data: any) => handleCategory(data, "category")} />
                                </div>
                              </Col>
                              <Col md={4}>
                                <div className={pm.formBox}>
                                  <label className="form-label">Tag </label>
                                  <MultiSelect list={serviceTagList?.map((item: any) => ({ label: item?.tags, value: item?._id }))} value={selectedTag} onChange={(data: any) => handleCategory(data, "tag")} />
                                </div>
                              </Col>
                              <Col md={4}>
                                <div className={pm.formBox}>
                                  <label className="form-label">Attribute1 </label>
                                  <MultiSelect list={attribute1List?.map((item: any) => ({ label: item?.name, value: item?._id }))} value={selectedAttribute1} onChange={(data: any) => handleCategory(data, "attribute1")} />
                                </div>
                              </Col>
                              <Col md={4}>
                                <div className={pm.formBox}>
                                  <label className="form-label">Attribute2 </label>
                                  <MultiSelect list={attribute2List?.map((item: any) => ({ label: item?.name, value: item?._id }))} value={selectedAttribute2} onChange={(data: any) => handleCategory(data, "attribute2")} />
                                </div>
                              </Col>
                              <Col md={4}>
                                <div className={pm.formBox}>
                                  <label className="form-label">Attribute3 </label>
                                  <MultiSelect list={attribute3List?.map((item: any) => ({ label: item?.name, value: item?._id }))} value={selectedAttribute3} onChange={(data: any) => handleCategory(data, "attribute3")} />
                                </div>
                              </Col>
                              <Col md={4}>
                                <div className={pm.formBox}>
                                  <label className="form-label">Priority </label>
                                  <select className="form-select" {...register("priority")}>
                                    <option value="">--Select--</option>
                                    {[1, 2, 3, 4, 5]?.map((item: any) => {
                                      return (
                                        <option value={item} key={item}>{item}</option>
                                      )
                                    })}
                                  </select>
                                </div>
                              </Col>
                            </Row>
                            <Row className="mt-3">
                              <Col md={3}>
                                <Row>
                                  <Col md={6}>
                                    <div className={`${pm.formBox} d-flex gap-3`}>
                                      <label className="form-label">Featured</label>
                                      <Checkbox register={{ ...register("feature") }} />
                                    </div>
                                  </Col>
                                  <Col md={6}>
                                    <div className={`${pm.formBox} d-flex gap-3`}>
                                      <label className="form-label">Trending</label>
                                      <Checkbox register={{ ...register("trending") }} />
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>

                      {collectionType !== "" && <Accordion>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>Sorting Order </Accordion.Header>
                          <Accordion.Body>
                            <Row>
                              <Col md={4}>
                                <div className={pm.formBox}>
                                  <label className="form-label">Order </label>
                                  <select className="form-select" {...register("sort",)}>
                                    <option value="">--Select--</option>
                                    <option value="location">Location</option>
                                    <option value="priority">Priority</option>
                                    <option value="name">Name</option>
                                  </select>
                                </div>
                              </Col>

                            </Row>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>}
                    </div>
                  </Col>
                </Row>
                {collectionType && <Row className="mt-3">
                  <Col md={12} lg={10}>
                    <div className={`${cx.addAccordiaon_features}`}>
                      <Accordion>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>Select Business</Accordion.Header>
                          <Accordion.Body>
                            <div>
                              <Row>
                                <Col lg={2} md={3} className="px-2">
                                  <div className={cx.price_categorySelect}>
                                    <div className={`${pm.formBox} mb-0`}>
                                      <label className="form-label">Sequence Number</label>
                                    </div>
                                  </div>
                                </Col>
                                <Col lg={6} md={6} className="px-2">
                                  <Col lg={11}>
                                    <div className={cx.price_categorySelect}>
                                      <div className={`${pm.formBox} mb-0`}>
                                        <label className="form-label">Assign Business</label>
                                      </div>
                                    </div>
                                  </Col>
                                </Col>
                                <Col lg={2} md={3} className="px-2">
                                  <div className={cx.price_categorySelect}>
                                    <div className={pm.formBox}>
                                      <label className="form-label">Business ID number</label>
                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              {actionArray?.map((item: any, i: number) => {
                                return (
                                  <Row className="align-items-center" key={i}>
                                    <Col lg={2} md={3} className="px-2">
                                      <div className={cx.price_categorySelect}>
                                        <div className={pm.formBox}>
                                          <label className="form-label">{item?.sq}</label>
                                        </div>
                                      </div>
                                    </Col>
                                    <Col lg={6} md={6} className="px-2">
                                      <Col lg={11}>
                                        <div className={cx.price_categorySelect}>
                                          <div className={pm.formBox} >
                                            <select
                                              className="form-select"
                                              value={item?.businessId}
                                              onChange={(e: any) => handleAddCat(i, e.target.value, "businessId")}
                                              style={errorTrig && item?.error ? { border: "1px solid red" } : {}}
                                            >
                                              <option value="">Select Business</option>
                                              {filterBusiness?.map((business: any) => {
                                                return (
                                                  <option value={business?._id} key={business?._id}>{business?.businessName}</option>
                                                )
                                              })}
                                            </select>

                                          </div>
                                        </div>
                                      </Col>
                                    </Col>
                                    <Col lg={3} md={3} className="px-2">
                                      <div className={cx.price_categorySelect}>
                                        <div className={pm.formBox}>
                                          <div>{item?.businessAccountNo}</div>
                                        </div>
                                      </div>
                                    </Col>
                                    <Col >
                                      <button className={cx.addBtn} onClick={() => handleAddRemoveHandler(i === 0 ? "inc" : "dec", i, "business")}>{i === 0 ? "+" : "-"}</button>
                                    </Col>
                                  </Row>
                                )
                              })}
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </Col>
                </Row>}

                {/* {collectionType === "promotion" && <Row className="mt-3">
                  <Col md={12} lg={10}>
                    <div className={`${cx.addAccordiaon_features}`}>
                      <Accordion>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>Select Promotion</Accordion.Header>
                          <Accordion.Body>
                            <div>
                              <Row>
                                <Col lg={2} md={3} className="px-2">
                                  <div className={cx.price_categorySelect}>
                                    <div className={`${pm.formBox} mb-0`}>
                                      <label className="form-label">Sequence Number</label>
                                    </div>
                                  </div>
                                </Col>
                                <Col lg={6} md={6} className="px-2">
                                  <Col lg={11}>
                                    <div className={cx.price_categorySelect}>
                                      <div className={`${pm.formBox} mb-0`}>
                                        <label className="form-label">Assign Promotion</label>
                                      </div>
                                    </div>
                                  </Col>
                                </Col>

                              </Row>

                              {actionArray?.map((item: any, i: number) => {
                                return (
                                  <Row className="align-items-center" key={i}>
                                    <Col lg={2} md={3} className="px-2">
                                      <div className={cx.price_categorySelect}>
                                        <div className={pm.formBox}>
                                          <label className="form-label">{item?.sq}</label>
                                        </div>
                                      </div>
                                    </Col>
                                    <Col lg={6} md={6} className="px-2">
                                      <Col lg={11}>
                                        <div className={cx.price_categorySelect}>
                                          <div className={pm.formBox} >
                                            <select
                                              className="form-select"
                                              value={item?.promotionId}
                                              onChange={(e: any) => handleAddCat(i, e.target.value, "promotionId")}
                                              style={errorTrig && (item?.promotionId === "" || item?.error) ? { border: "1px solid red" } : {}}
                                            >
                                              <option value="">Select Promotion</option>
                                              {promotionList?.map((promotion: any) => {
                                                return (
                                                  <option value={promotion?._id} key={promotion?._id}>{promotion?.promotionName}</option>
                                                )
                                              })}
                                            </select>

                                          </div>
                                        </div>
                                      </Col>
                                    </Col>

                                    <Col >
                                      <button className={cx.addBtn} onClick={() => handleAddRemoveHandler(i === 0 ? "inc" : "dec", i, "promotion")}>{i === 0 ? "+" : "-"}</button>
                                    </Col>
                                  </Row>
                                )
                              })}
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </Col>
                </Row>} */}

                <Row>
                  <Col xl={3} lg={3} md={4} className="mt-3">
                    <div className={cx.price_categorySelect}>
                      <div className={pm.formBox}>
                        <label className="form-label">Image</label>
                        <div className={`${st.uploadForm}`}>
                          <div className={`${st.fileUpload} ${st.pageUpload} `}>
                            {!imageagain && !editData?.image && <input
                              type="file"
                              onChange={(event) => {
                                handleImageChange(event);
                              }}
                            />}
                            {!imageagain && !editData?.image ? (
                              <img src={uploadFile} className={`${st.icon}`} alt="Icon" />
                            ) : (
                              <div className={cx.imageBox}>
                                <img src={icon} className={`${st.icon}`} alt="Icon" />
                                <label htmlFor="upload" className={cx.uploadLabel}>
                                  <input
                                    type="file"
                                    id="upload"
                                    onChange={(event) => {
                                      handleImageChange(event);
                                    }}
                                  />
                                  <img src={uploadIcon} alt="" className={cx.uploadVector} />
                                </label>
                              </div>
                            )}

                          </div>


                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>


              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </section>
    </>
  );
};

export default AddCollection;
