import React, { useCallback, useEffect, useState } from "react";
import st from "../../../style.module.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import cx from "./BranchAndLocation.module.scss";
import tb from "../../../datatable.module.scss";
import { Card, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import { AiFillDelete, AiOutlinePlusCircle } from "react-icons/ai";
import { FiUpload } from "react-icons/fi";

// --------- Images --------- //
import DemoInfo from "../../../components/DemoInfo/DemoInfo";

import SettingsMenu from "../../../components/Sidebar/SettingsMenu";
import TableDataGrid from "./DataGrid";
import DeletePopup from "../../../components/Modals/DeletePopup";
import AmenitiesM from "../../../components/Modals/Amenities";
import AddTag from "../../../components/Modals/AddTag";
import { httpRequest } from "../../../Apis/commonApis";
import SuccessPasswordModal from "../../../components/Modals/SuccessPasswordModal";

export default function Tag() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [type, setType] = useState<any>('success');
  const [catagoryData, setCatagoryData] = useState<any>([]);

  const [showSuccess, setShowSuccess] = useState<boolean>(false)
  function handleSuccessClose() {
    setType('success')
    setShowSuccess(false)
  }

  const [serviceList, setServiceList] = useState<any[]>([])
  useEffect(() => {
    getServiceTag()
  }, [])
  const getServiceTag = async () => {
    let res = await httpRequest(`get_ServiceTag?type=list`, "get", null, "auth");
    if (res?.status) {
      setServiceList(res.data)
    }
  }

  async function handleUpload(e: any) {
    const file = e.target.files[0]
    if (file) {
      const formdata = new FormData()
      formdata.append('attachment', file)
      const response = await httpRequest('serviceTagbulkUpload', "POST", formdata, 'formdata');
      if (response?.status) {
        getServiceTag()
        setShowSuccess(true)
        setType('success')
      } else {
        setShowSuccess(true)
        setType('errror')
      }
    }
  }

  useEffect(() => {
    getCatagory();
  }, []);
  const getCatagory = async () => {
    const res = await httpRequest("get_Catagory", "get", null, null);
    if (res.status) {
      // setCatagoryData(res?.data)
      const list = res?.data
      const categoryOptions = list?.[0]?.label ? list : list?.map((tag: any) => {
        return { value: tag?._id, label: tag?.BusinessCategoryName };
      });
      setCatagoryData(categoryOptions)
    }
  }

  return (
    <>
      <SuccessPasswordModal handleClose={handleSuccessClose} show={showSuccess} message={type === 'success' ? 'file uploaded successfully' : 'file upload failed'} type={type} />
      <section className={`${st.pageWrapper} ${st.pageWrapperOpen}`}>
        <SettingsMenu />
        <div className={`${st.pageTitle}`}>
          <Row>
            <Col md={6} lg={6}>
              <div className={`${st.titleInfo}`}>
                <div className={`${st.titleInfoBody}`}>
                  <h5>Settings</h5>
                  <p>Tag</p>
                </div>
              </div>
            </Col>
            <Col md={6} lg={6}>
              <DemoInfo />
            </Col>
          </Row>
        </div>

        <div className={`${st.pageWrapperInside}`}>
          <Card>
            <Card.Body>
              <Row className={`${tb.tableAction}`}>
                <Col lg={12}>
                  <div className="d-flex">
                    <NavLink
                      to="#"
                      className={`btn ${tb.addBtn}`}
                      onClick={handleShow}
                    >
                      <AiOutlinePlusCircle className="me-1" />
                      Add New Tag
                    </NavLink>
                    <button className={`btn ${tb.upload}`}>
                      <FiUpload className={`${tb.icon}`} />
                      Upload CSV
                      <input type="file" accept=".xls,.xlsx,.csv" onChange={handleUpload} />
                    </button>

                    <button className={`btn ${tb.upload}`}>
                      <AiFillDelete className={`${tb.icon} m-0`} />
                    </button>
                  </div>
                </Col>
              </Row>
              <div className={`${tb.dataTable}`}>
                <TableDataGrid serviceList={serviceList} getServiceTag={getServiceTag} />
              </div>
            </Card.Body>
          </Card>
        </div>
      </section>

      {show && <AddTag show={show} handleClose={handleClose} getServiceTag={getServiceTag} catagoryData={catagoryData}/>}
    </>
  );
}
