import React, { useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import tb from "../../../datatable.module.scss";
import { NavLink } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import Checkbox from "../../../components/Forms/Checkbox";
import st from "../../../style.module.scss";

import category1 from "../../../images/Chair.svg";
import Status from "../../../components/Forms/Status";
import AddCategory from "../../../components/Modals/AddCategory";
import DeletePopup from "../../../components/Modals/DeletePopup";
import { httpRequest } from "../../../Apis/commonApis";

const StatusButton = (props: any) => {
  let { params, data, getCatagory } = props;
  const [editData, setEditData] = useState(null)
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setEditData(null)
    setShow(false)
  }
  const handleShow = () => {
    const filterData = data?.find((item: any) => item?._id === params?.row?.id)
    setEditData(filterData)
    setShow(true);
  }

  const [showDelete, setDeleteShow] = useState(false);
  const handleDeleteClose = () => setDeleteShow(false);
  const handleDeleteShow = () => setDeleteShow(true);

  const deleteCatagoryApi = async () => {
    const res = await httpRequest(
      `delete_Catagory/${params?.id}`,
      "DELETE",
      null,
      "withoutToken"
    );
    if (res.status) {
      getCatagory && getCatagory()
    }
  };


  return (
    <>
      <div>
        <ul className={`${tb.actionTable}`}>
          <li>
            <NavLink
              className={`btn ${tb.edit}`}
              title="Edit"
              to="#"
              onClick={handleShow}
            >
              <MdEdit />
            </NavLink>
          </li>
          <li>
            <NavLink
              className={`btn ${tb.delete}`}
              title="Delete"
              to="#"
              onClick={handleDeleteShow}
            >
              <AiFillDelete />
            </NavLink>
          </li>
          <li>
            <Status status={params?.row?.isActive} api="update_Catagory" id={params?.id}
              apiMethod="formdata" getLatest={getCatagory} item={params?.row?.category || ''} />
          </li>
        </ul>
      </div>

      {show && <AddCategory show={show} params={params} handleClose={handleClose} editData={editData} getCatagory={getCatagory} />}
      <DeletePopup
        show={showDelete}
        deleteCatagoryApi={deleteCatagoryApi}
        handleDeleteClose={handleDeleteClose}
      />
    </>
  );
};
const IconsCategory = (props: any) => {
  let { params } = props;
  return (
    <div>
      {params?.row?.Icon && <img style={{ height: '100px', width: '100px', borderRadius: '50%' }} src={params?.row?.Icon} alt="icon" />}
    </div>
  );
};
const IconsCategoryy = (props: any) => {
  let { params } = props;
  return (
    <div>
      {params.row.image && <img src={params?.row?.image} style={{ height: '100px', width: '100px', borderRadius: '50%' }} alt="image" />}
    </div>
  );
};
export default function TableDataGrid(props: any) {
  let data = props?.CatagoryData;
  const columns = [
    { field: "Sr_No", headerName: "Sr No", flex: 1, minWidth: 60 },
    {
      field: "category",
      headerName: "Business Category Name",
      flex: 1,
      minWidth: 300,
    },
    {
      field: "icon",
      headerName: "Icon",
      flex: 1,
      minWidth: 120,
      renderCell: (params: any) => <IconsCategory params={params} key={params?.row?.id} />,
    },
    {
      field: "image",
      headerName: "Image",
      flex: 1,
      minWidth: 120,
      renderCell: (params: any) => <IconsCategoryy params={params} key={params?.row?.id} />,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 280,
      sortable: true, // Enable sorting
      sortComparator: (v1: any, v2: any) => {
        // Custom comparator for sorting the status (isActive)
        if (v1 === v2) return 0;
        return v1 ? -1 : 1; // Active (true) first, Inactive (false) second
      },
      renderCell: (params: any) => <StatusButton params={params} data={data} getCatagory={props.getCatagory} key={params?.row?.id} />

    },
  ];

  let row: any = data?.map((val: any, index: any) => {
    return {
      Sr_No: index + 1,
      id: val?._id,
      isActive: val?.isActive,
      category: val?.BusinessCategoryName,
      Icon: val?.Icon ? val?.Icon : '',
      image: val?.image ? val?.image : '',
      action: val?.isActive,
    };
  });

  return (
    <div style={{ width: "100%" }}>
      <DataGrid
        columns={columns}
        rows={row}
        pageSize={100}
        //onRowClick={handleRowClick}
        autoHeight
        hideFooterPagination={true}
        rowHeight={48}
        headerHeight={48}
        checkboxSelection
        disableSelectionOnClick
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      />

    </div>
  );
}
