import React, { useCallback, useEffect, useState } from "react";
import st from "../../../style.module.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import cx from "./index.module.scss";
import tb from "../../../datatable.module.scss";
import { Card, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import { AiFillDelete, AiOutlinePlusCircle } from "react-icons/ai";
import { FiUpload } from "react-icons/fi";

// --------- Images --------- //
import DemoInfo from "../../../components/DemoInfo/DemoInfo";

import SettingsMenu from "../../../components/Sidebar/SettingsMenu";

import DeletePopup from "../../../components/Modals/DeletePopup";
import AmenitiesM from "../../../components/Modals/Amenities";
import BusinessMenu from "../../../components/Sidebar/BusinessMenu";
import CustomersMenu from "../../../components/Sidebar/CustomersMenu";
import WebpageMenu from "../../../components/Sidebar/WebpageMenu";
import ImageManagementM from "../../../components/Modals/ImageManagementModal";
import SuccessPasswordModal from "../../../components/Modals/SuccessPasswordModal";
import { httpRequest } from "../../../Apis/commonApis";
import CollectionGrid from "./DataGrid";

export default function Collections() {
  const [collectionList, setCollectionList] = useState([])

  async function getCollection() {
    const res = await httpRequest(`collection_cms`, "GET", null, "json");
    if(res.status){
      setCollectionList(res?.data)
    }
  }

  useEffect(() =>{
    getCollection()
  },[])
  
  return (
    <>
      <section className={`${st.pageWrapper} ${st.pageWrapperOpen}`}>
        <WebpageMenu />
        <div className={`${st.pageTitle}`}>
          <Row>
            <Col md={6} lg={6}>
              <div className={`${st.titleInfo}`}>
                <div className={`${st.titleInfoBody}`}>
                  <h5>Collection list</h5>
                  <p>Collection management</p>
                </div>
              </div>
            </Col>
            <Col md={6} lg={6}>
              <DemoInfo />
            </Col>
          </Row>
        </div>

        <div className={`${st.pageWrapperInside}`}>
          <Card>
            <Card.Body>
              <Row className={`${tb.tableAction}`}>
                <Col lg={12}>
                  <div className="d-flex">
                    <NavLink
                      to="add-collection"
                      className={`btn ${tb.addBtn}`}
                    // onClick={handleShow}
                    >
                      <AiOutlinePlusCircle className="me-1" />
                      Add New Collection
                    </NavLink>
                    <button className={`btn ${tb.upload}`}>
                      <FiUpload className={`${tb.icon}`} />
                      Upload CSV
                      <input type="file" />
                    </button>

                    <button className={`btn ${tb.upload}`}>
                      <AiFillDelete className={`${tb.icon} m-0`} />
                    </button>
                  </div>
                </Col>
              </Row>
              <div className={`${tb.dataTable}`}>
                <CollectionGrid collectionList={collectionList} getCollection={getCollection}/>
              </div>
            </Card.Body>
          </Card>
        </div>
      </section>


    </>
  );
}
