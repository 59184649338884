import React, { useState, useEffect } from "react";
import m from "../../modal.module.scss";
import st from "../../style.module.scss";
import { NavLink } from "react-router-dom";
import { AiFillDelete, AiOutlineGlobal, AiOutlineInfoCircle } from "react-icons/ai";
import cx from "./Model.module.scss";
import tb from "../../datatable.module.scss";
import uploadFile from "../../images/iconUpload.svg";
import { Card, Row, Col, Form, Modal, Button, Tab, Nav, InputGroup } from "react-bootstrap";
import { MdClose, MdContentCopy, MdEdit, MdLocationPin, MdOutlineCheck, MdOutlineFileUpload } from "react-icons/md";
import PhoneNumber from "../Phonenumber/Phonenumber";
import { httpRequest } from "../../Apis/commonApis";
import Select from "react-select";

const EditBusinessManagement = (props: any) => {
  const { show, handleClose, data } = props;
  let getBusinessInfoDataData = props?.getBusinessInfoData;

  const initialValue = {
    businessName: "",
    attribute1: [],
    attribute2: [],
    attribute3: [],
    priority: "",
  };

  const [businessInfo, setBusinessInfo] = useState<any>({});
  const [businessData, setBusinessData] = useState<any>(initialValue);
  const [loading, setLoading] = useState(true);
  const [attribute1, setAttribute1] = useState<any[]>([]);
  const [attribute2, setAttribute2] = useState<any[]>([]);
  const [attribute3, setAttribute3] = useState<any[]>([]);


  console.log(businessData, "businessData")
  useEffect(() => {
    if (show && data) {
      getBusinessInfo();
      getAttribute1();
      getAttribute2();
      getAttribute3();
    }
  }, [show, data]);


  const getAttribute1 = async () => {
    let res = await httpRequest("attribute1", "get", null, "auth");
    if (res?.status) {
      setAttribute1(res.data.map((item: any) => ({ value: item._id, label: item.name })));
    }
  };

  const getAttribute2 = async () => {
    let res = await httpRequest("attribute2", "get", null, "auth");
    if (res?.status) {
      setAttribute2(res.data.map((item: any) => ({ value: item._id, label: item.name })));
    }
  };

  const getAttribute3 = async () => {
    let res = await httpRequest("attribute3", "get", null, "auth");
    if (res?.status) {
      setAttribute3(res.data.map((item: any) => ({ value: item._id, label: item.name })));
    }
  };

  useEffect(() => {
    setBusinessData({
      businessName: businessInfo?.businessName || "",
      attribute1: businessInfo?.attribute1?.filter((item: any) => item)?.map((attr: any) => ({ value: attr?._id, label: attr?.name })) || [],
      attribute2: businessInfo?.attribute2?.filter((item: any) => item)?.map((attr: any) => ({ value: attr?._id, label: attr?.name })) || [],
      attribute3: businessInfo?.attribute3?.filter((item: any) => item)?.map((attr: any) => ({ value: attr?._id, label: attr?.name })) || [],
      priority: businessInfo?.priority || "",
    });
  }, [businessInfo]);

  const getBusinessInfo = async () => {
    const res = await httpRequest(
      `get_BusinessInfo?_id=${data}`,
      "get",
      null,
      "withoutToken"
    );
    if (res?.status === true) {
      setLoading(false);
      const selectedData = res?.data?.find((item: any) => item?._id === data);
      setBusinessInfo(selectedData || {});
    }
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setBusinessData({ ...businessData, [name]: value });
  };

  const handleMultiSelectChange = (selectedOptions: any, { name }: any) => {
    setBusinessData({ ...businessData, [name]: selectedOptions });
  };

  const updateBusinessInfo = async () => {
    const payload = {
      ...businessData,
      attribute1: businessData?.attribute1?.map((item: any) => item.value),
      attribute2: businessData?.attribute2?.map((item: any) => item.value),
      attribute3: businessData?.attribute3?.map((item: any) => item.value),
    };
    console.log(businessInfo, "businessInfo")
    const res = await httpRequest(`business_Info/${businessInfo?._id}`, "PATCH", payload, "json");
    if (res?.status === true) {
      setLoading(false);
      getBusinessInfoDataData();
      getBusinessInfo();
      handleClose();
    } else {
      // Handle the error case
    }
  };

  return (
    <Modal
      size="sm"
      centered
      show={show}
      onHide={handleClose}
      className={`${m.modalCts}`}
    >
      <Modal.Body>
        <button className={`${m.closeIcon}`} title="Close" onClick={handleClose}>
          <MdClose />
        </button>
        <Col lg={12} className={`${m.title}`}>
          <h5>Edit Business User</h5>
        </Col>
        <Row>
          <Col md={12} lg={12}>
            <Row>
              <Col lg={12}>
                <Form.Group className={`${st.formField}`}>
                  <Form.Label>
                    Business Name
                  </Form.Label>
                  <input
                    type="text"
                    className="form-control"
                    name="businessName"
                    value={businessData?.businessName}
                    disabled
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Form.Group className={`${st.formField}`}>
                  <Form.Label>
                    Attribute1
                  </Form.Label>
                  <Select
                    isMulti
                    name="attribute1"
                    value={businessData.attribute1}
                    options={attribute1}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={handleMultiSelectChange}
                  />
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Form.Group className={`${st.formField}`}>
                  <Form.Label>
                    Attribute2
                  </Form.Label>
                  <Select
                    isMulti
                    name="attribute2"
                    value={businessData.attribute2}
                    options={attribute2}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={handleMultiSelectChange}
                  />
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Form.Group className={`${st.formField}`}>
                  <Form.Label>
                    Attribute3
                  </Form.Label>
                  <Select
                    isMulti
                    name="attribute3"
                    value={businessData.attribute3}
                    options={attribute3}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={handleMultiSelectChange}
                  />
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Form.Group className={`${st.formField}`}>
                  <Form.Label>
                    Priority Attribute<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    as="select"
                    name="priority"
                    value={businessData.priority}
                    onChange={handleInputChange}
                    style={{padding:'2px 15px', height:'39px', lineHeight:'14px', borderRadius:'5px', borderColor:'#cccccc'}}
                  >
                    <option value="" disabled>
                      Select Priority
                    </option>
                    {[1, 2, 3, 4, 5]?.map((priority) => (
                      <option key={priority} value={priority}>
                        {priority}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>

              <Col lg={12} className="mt-2">
                <Button
                  className={`${st.btnDisabled}`}
                  onClick={updateBusinessInfo}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default EditBusinessManagement;
