import React, { useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import tb from "../../../datatable.module.scss";
import { NavLink } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import Checkbox from "../../../components/Forms/Checkbox";
import st from "../../../style.module.scss";

import category1 from "../../../images/Chair.svg";
import Status from "../../../components/Forms/Status";
import AddCategory from "../../../components/Modals/AddCategory";
import DeletePopup from "../../../components/Modals/DeletePopup";
import AddTag from "../../../components/Modals/AddTag";
import { httpRequest } from "../../../Apis/commonApis";

const StatusButton = (props: any) => {
  let { params, getServiceTag } = props;

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showDelete, setDeleteShow] = useState(false);
  const handleDeleteClose = () => setDeleteShow(false);
  const handleDeleteShow = () => setDeleteShow(true);
  const deleteServiceTag = async () => {
    let res = await httpRequest(`delete_ServiceTag/${params?.row?.id}`, "Delete", null, "auth");
    if (res?.status) {
      handleDeleteClose();
      getServiceTag()
    }
  }

  return (
    <>
      <div>
        <ul className={`${tb.actionTable}`}>
          {/* <li>
            <NavLink
              className={`btn ${tb.edit}`}
              title="Edit"
              to="#"
              onClick={handleShow}
            >
              <MdEdit />
            </NavLink>
          </li> */}
          <li>
            <NavLink
              className={`btn ${tb.delete}`}
              title="Delete"
              to="#"
              onClick={handleDeleteShow}
            >
              <AiFillDelete />
            </NavLink>
          </li>
          <li>
            <Status status={params?.row?.isActive} api="updateServiceTags" id={params?.row?.id} apiMethod="json" getLatest={getServiceTag} item={params?.row?.tag || ''} />
          </li>
        </ul>
      </div>

      <AddTag show={show} handleClose={handleClose} />
      <DeletePopup show={showDelete} deleteServiceTag={deleteServiceTag} handleDeleteClose={handleDeleteClose} />
    </>
  );
};



export default function TableDataGrid(props: any) {
  let { serviceList, getServiceTag } = props
  let row: any = serviceList?.map((item: any, index: any) => {
    console.log(item?.businessCategoryId, "item")
    let arrCat: any = []

    if (Array.isArray(item?.businessCategoryId)) {
      item?.businessCategoryId.map((cat: any) => arrCat.push(cat?.BusinessCategoryName));
    } else {

      if (item?.businessCategoryId) {
        arrCat.push(item.businessCategoryId?.BusinessCategoryName);
      }
    }

    //  item?.businessCategoryId.map((cat: any) => arrCat.push(cat?.BusinessCategoryName))
    return {
      Sr_No: index + 1,
      id: item?._id,
      tag: item?.tags,
      businesscategory: arrCat,
      isActive: item?.isActive,
      action: item?.isActive
    }
  })
  const columns = [
    { field: "Sr_No", headerName: "Sr No", flex: 1, minWidth: 60 },

    {
      field: "tag",
      headerName: "Tag",
      flex: 2,
      minWidth: 200,
    },
    {
      field: "businesscategory",
      headerName: "Business Category *",
      flex: 2,
      minWidth: 250,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 2,
      minWidth: 280,
      sortable: true, // Enable sorting
      sortComparator: (v1: any, v2: any) => {
        // Custom comparator for sorting the status (isActive)
        if (v1 === v2) return 0;
        return v1 ? -1 : 1; // Active (true) first, Inactive (false) second
      },
      renderCell: (params: any) => <StatusButton params={params} getServiceTag={getServiceTag} />,
    },
  ];

  return (


    <div style={{ width: "100%" }}>
      <DataGrid
        columns={columns}
        rows={row}
        autoHeight
        pageSize={100}
        rowHeight={48}
        headerHeight={48}
        checkboxSelection
        disableSelectionOnClick
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      />
    </div>
    // <div style={{ width: "100%" }}>
    //   <DataGrid
    //     columns={columns}
    //     rows={row}
    //     // onRowClick={handleRowClick}
    //     autoHeight
    //     hideFooterPagination={true}
    //     pageSize={100}
    //     rowHeight={48}
    //     headerHeight={48}
    //     checkboxSelection
    //     disableSelectionOnClick
    //     components={{ Toolbar: GridToolbar }}
    //     componentsProps={{
    //         toolbar: {
    //             showQuickFilter: true,
    //             quickFilterProps: { debounceMs: 500 },
    //         },
    //     }}
    //   />
    // </div>
  );
}
