import React, { useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Tooltip from '@mui/material/Tooltip';

export default function TableDataGrid(props: any) {
  const renderCellWithTooltip = (params: any) => (
    <Tooltip title={params?.value}>
      <div>{params?.value}</div>
    </Tooltip>
  );
  let row = props?.membershipdata?.map((item: any, index: any) => {
    return {
      Sr_No: index + 1,
      id: item?._id,
      Membership: item?.Membership,
      Price: item?.retailPrice,
      Validfor: item?.Validity,
      BusinessName: item?.Business?.businessName,
      BranchName: item?.BranchLocation?.branchName,
      Spent: item?.amountSpent,
      Hours: item?.hoursBooked,
      Point: item?.Points,
    }
  })
  const columns = [
    { field: "Sr_No", headerName: "Sr No", flex: 1, minWidth: 60 },
    {
      field: "Membership",
      headerName: "Membership",
      flex: 2,
      minWidth: 120, renderCell: renderCellWithTooltip
    },
    {
      field: "BusinessName",
      headerName: "Business Name",
      flex: 2,
      minWidth: 160, renderCell: renderCellWithTooltip
    },
    {
      field: "BranchName",
      headerName: "Branch Name",
      flex: 2,
      minWidth: 160, renderCell: renderCellWithTooltip
    },
    {
      field: "Price",
      headerName: "Price",
      flex: 2,
      minWidth: 120, renderCell: renderCellWithTooltip
    },
    {
      field: "Validfor",
      headerName: "Valid for",
      flex: 2,
      minWidth: 120, renderCell: renderCellWithTooltip
    },
    {
      field: "Spent",
      headerName: "Spent",
      flex: 2,
      minWidth: 120, renderCell: renderCellWithTooltip
    },
    {
      field: "Hours",
      headerName: "Hours",
      flex: 2,
      minWidth: 120, renderCell: renderCellWithTooltip
    },
    {
      field: "Point",
      headerName: "Point",
      flex: 2,
      minWidth: 120, renderCell: renderCellWithTooltip
    },

    // {
    //   field: "action",
    //   headerName: "Action",
    //   flex: 1,
    //   minWidth: 280,
    //   renderCell: (params: any) => <StatusButton params={params} />,
    // },
  ];

  return (
    <div style={{ width: "100%" }}>
      <DataGrid
        columns={columns}
        rows={row}
        autoHeight
        pageSize={100}
        // hideFooterPagination={false}
        rowHeight={48}
        checkboxSelection
        disableSelectionOnClick
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      />
    </div>
  );
}
