import React from "react";
import { Modal, Button } from "react-bootstrap";

const ViewKeywordModal = ({ show, handleClose, keywords }:any) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Search Keywords</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {keywords && keywords.length > 0 ? (
          <ul>
            {keywords.map((keyword:any, index:any) => (
              <div key={index}>{keyword}</div>
            ))}
          </ul>
        ) : (
          <p>No keywords available</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ViewKeywordModal;
