import React, { useState, useEffect } from "react";
import m from "../../modal.module.scss";
import st from "../../style.module.scss";
import { NavLink } from "react-router-dom";
import cx from "./Model.module.scss";
import tb from "../../datatable.module.scss";
import { Card, Row, Col, Form, Modal, Button, Tab, Nav, InputGroup } from "react-bootstrap";
import { MdClose, MdContentCopy, MdEdit, MdLocationPin, MdOutlineCheck, MdOutlineFileUpload } from "react-icons/md";

import Select from "react-select";
import { httpRequest } from "../../Apis/commonApis";


const BusinessContactDetails = (props: any) => {

    let [loading, setLoading] = useState(true);  
     const [businessInfo, setsetbusinessInfo] = useState<any>([]);
     console.log(businessInfo,"businessInfo")
    let { show, handleBusinessContactClose ,ContactDetailsData} = props;
    let businessData:any = ContactDetailsData
    // console.log(businessData?.[0]?._id,"kkk")
    const catData = businessInfo?.[0]?.businessCatagoryId?.map((item:any)=>{
        return item?.BusinessCategoryName
    })
    const Attribute1 = businessInfo?.[0]?.attribute1?.map((item:any)=>{
        return item?.name
    })
    const Attribute2 = businessInfo?.[0]?.attribute2?.map((item:any)=>{
        return item?.name
    })
    const Attribute3 = businessInfo?.[0]?.attribute3?.map((item:any)=>{
        return item?.name
    })
    const AsearchArray = businessInfo?.[0]?.searchArray?.map((item:any)=>{
        return item;
    })

    // console.log(businessData?.BusinessUser?.emailVerified,"lll")

    useEffect(()=>{
        getBusinessInfo()
    },[])

    const getBusinessInfo = async () => {
        const res = await httpRequest(`get_BusinessInfo?_id=${businessData?._id}`, "get", null, "withoutToken");
        if (res?.status == true) {
            setLoading(false)
            setsetbusinessInfo(res?.data)
        }
    };


    return (

        <>
            <Modal
                size="lg"
                centered
                show={show}
                onHide={handleBusinessContactClose}
                className={`${m.modalCts} ${m.modalSizeLg} ${m.modalBusinessDetails}`}

            >
                <Modal.Body>
                    <button className={`${m.closeIcon}`} title="Close" onClick={handleBusinessContactClose}>
                        <MdClose />
                    </button>
                    <Col lg={12} className={`${m.title}`}>
                        <h5>{businessInfo?.[0]?.businessName}:{businessInfo?.[0]?.businessAccountNo}</h5>
                    </Col>
                    <div className={`${m.businessCategory_body}`}>
                        <Col md={12}>
                            <div className={`${m.business_mainHeding}`}>
                                <h3>Contact Details</h3>
                            </div>
                        </Col>
                        <Row className="px-2 g-2">

                            <Col md={4}>
                                <div className={`${m.business_subHeding}`}>
                                    <h6>Contact Person : <span>{businessInfo?.[0]?.BusinessUser?.firstName}{businessData?.BusinessUser?.lastName}</span></h6>
                                    {/* <h6>Contact Person : <span>{businessData?.BusinessUser?.firstName}{businessData?.BusinessUser?.lastName}</span></h6> */}
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className={`${m.business_subHeding}`}>
                                    <h6>Phone Number : <span>{businessInfo?.[0]?.BusinessUser?.mobile}</span></h6>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className={`${m.business_subHeding}`}>
                                    <h6>Email : <span>{businessInfo?.[0]?.BusinessUser?.email}</span></h6>
                                </div>
                            </Col>

                        </Row>
                        <hr />
                        <Col md={12}>
                            <div className={`${m.business_mainHeding}`}>
                                <h3>Business Details</h3>
                            </div>
                        </Col>
                        <Row className="px-2 g-2">
                            <Col md={4}>
                                <div className={`${m.business_subHeding}`}>
                                    <h6>Category : <span>{catData?.join(', ')}</span></h6>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className={`${m.business_subHeding}`}>
                                    <h6>Tags: <span>{AsearchArray?.join(', ')}</span></h6>
                                </div>
                            </Col>
                        </Row>
                        <Row className="px-2 g-2 ">
                            <Col md={4}>
                                <div className={`${m.business_subHeding}`}>
                                    <h6>Attribute 1 : <span>{Attribute1?.join(', ')}</span></h6>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className={`${m.business_subHeding}`}>
                                    <h6>Attribute 2 : <span>{Attribute2?.join(', ')}</span></h6>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className={`${m.business_subHeding}`}>
                                    <h6>Attribute 3 : <span>{Attribute3?.join(', ')}</span></h6>
                                </div>
                            </Col>
                        </Row>
                        <hr />
                        <Col md={12}>
                            <div className={`${m.business_mainHeding}`}>
                                <h3>Status</h3>
                            </div>
                        </Col>
                        <Row className="px-2 g-2">
                            <Col md={4}>
                                <div className={`${m.business_subHeding}`}>
                                    <h6>Email : <span>{businessInfo?.[0]?.BusinessUser?.emailVerified ? "varified" : "not varified"}</span></h6>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className={`${m.business_subHeding}`}>
                                    <h6>Mobile : <span>{businessInfo?.[0]?.BusinessUser?.mobileVerified ? "varified" : "not varified"}</span></h6>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className={`${m.business_subHeding}`}>
                                    <h6>Status : <span>{businessInfo?.[0]?.BusinessUser?.status ? "true" : "false"}</span></h6>
                                </div>
                            </Col>
                        </Row>
                        <Row className="px-2 g-2 ">
                            <Col md={4}>
                                <div className={`${m.business_subHeding}`}>
                                    <h6>Log In : <span>Active</span></h6>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className={`${m.business_subHeding}`}>
                                    <h6>Publication : <span>Active</span></h6>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className={`${m.business_subHeding}`}>
                                    <h6>Featured : <span>{businessInfo?.[0]?.featured ? "Yes" : "No"}</span></h6>
                                </div>
                            </Col>
                        </Row>
                        <Row className="px-2 g-2 ">
                            <Col md={4}>
                                <div className={`${m.business_subHeding}`}>
                                    <h6>Trending : <span>{businessInfo?.[0]?.trending ? "Yes" : "No"}</span></h6>
                                </div>
                            </Col>
                        </Row>
                        <hr />
                        <Col md={12}>
                            <div className={`${m.business_mainHeding}`}>
                                <h3>Statsictics</h3>
                            </div>
                        </Col>
                        <Row className="px-2 g-2">

                            <Col md={4}>
                                <div className={`${m.business_subHeding}`}>
                                    <h6>Active Clients : <span>{businessInfo?.[0]?.clientCount}</span></h6>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className={`${m.business_subHeding}`}>
                                    <h6>Active Team Member : <span>{businessInfo?.[0]?.teamCount}</span></h6>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className={`${m.business_subHeding}`}>
                                    <h6>Active users on app : <span>{''}</span></h6>
                                </div>
                            </Col>
                        </Row>
                        <hr />
                        <Col md={12}>
                            <div className={`${m.business_mainHeding}`}>
                                <h3>Branches</h3>
                            </div>
                        </Col>
                        <Row className="px-2 g-2">

                            <Col md={6}>
                                <h4 className={`${m.business_branchHeading}`}>1. Branch Name</h4>
                                <div className="pe-1 ps-3">
                                    <div className={`${m.business_subHeding}`}>
                                        <h6>Contact person : <span>Lorem content</span></h6>
                                    </div>
                                    <div className={`${m.business_subHeding}`}>
                                        <h6>Email : <span>Lorem Ipsum</span></h6>
                                    </div>
                                    <div className={`${m.business_subHeding}`}>
                                        <h6>Phone : <span>0987654321</span></h6>
                                    </div>
                                    <div className={`${m.business_subHeding}`}>
                                        <h6>Address : <span>Lorem Ipsum</span></h6>
                                    </div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <h4 className={`${m.business_branchHeading}`}>2. Branch Name</h4>
                                <div className="pe-1 ps-3">
                                    <div className={`${m.business_subHeding}`}>
                                        <h6>Contact person : <span>Lorem Ipsum</span></h6>
                                    </div>
                                    <div className={`${m.business_subHeding}`}>
                                        <h6>Email : <span>Lorem Ipsum</span></h6>
                                    </div>
                                    <div className={`${m.business_subHeding}`}>
                                        <h6>Phone : <span>0987654321</span></h6>
                                    </div>
                                    <div className={`${m.business_subHeding}`}>
                                        <h6>Address : <span>Lorem Ipsum</span></h6>
                                    </div>
                                </div>
                            </Col>

                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default BusinessContactDetails