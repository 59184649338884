import React, { useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import tb from "../../../datatable.module.scss";
import { NavLink } from "react-router-dom";
import { AiFillDelete } from "react-icons/ai";
import Tooltip from '@mui/material/Tooltip';
import Status from "../../../components/Forms/Status";
import DeletePopup from "../../../components/Modals/DeletePopup";
import AddBranch from "../../../components/Modals/AddBranch";
import { httpRequest } from "../../../Apis/commonApis";

const StatusButton = (props: any) => {
  let { params, branchlist } = props;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [showDelete, setDeleteShow] = useState(false);
  const handleDeleteClose = () => setDeleteShow(false);
  const handleDeleteShow = () => setDeleteShow(true);

  const deleteBranch = async () => {
    const res = await httpRequest(`delete_Branch?id=${params?.id}`, "DELETE", null, "withoutToken");
    if (res?.status == true) {
      window.location.reload();
    } else {
    }
  };
  return (
    <>
      <div>
        <ul className={`${tb.actionTable}`}>
          <li>
            <NavLink
              className={`btn ${tb.delete}`}
              title="Delete"
              to="#"
              onClick={handleDeleteShow}
            >
              <AiFillDelete />
            </NavLink>
          </li>
          {/* <li>
            <Status status={params.status} api="branch_status" id={params?.id}
              apiMethod="json" getLatest={branchlist} />
          </li> */}
        </ul>
      </div>
      <AddBranch show={show} handleClose={handleClose} />
      <DeletePopup show={showDelete} deleteBranch={deleteBranch} handleDeleteClose={handleDeleteClose} />
    </>
  );
};
export default function TableDataGrid(props: any) {
  const [rowSelectionModel, setRowSelectionModel] = useState<any>([]);
  const renderCellWithTooltip = (params: any) => (
    <Tooltip title={params?.value}>
      <div>{params?.value}</div>
    </Tooltip>
  );
  let data = props?.branchData;
  let row = data?.map((val: any, index: any) => {
    return {
      Sr_No: index + 1,
      id: val?._id,
      businessName: val?.Business?.businessName,
      email: val?.email,
      branchName: val?.branchName,
      branchNo: val?.branchNo,
      businessCountry: val?.Business?.country,
      businessAccountNo: val?.Business?.businessAccountNo,
      createdBy: val?.createdBy,
      updatedBy: val?.updatedBy,
      city: val?.city,
      status: val?.status,
      facebook: val?.facebook,
      instagram: val?.instagram,
      website: val?.website,
      postalCode: val?.postalCode,
      landmark: val?.landmark,
      address1: val?.address1,
      address2: val?.address2,
      phoneNumber1: val?.phoneNumber1,
      phoneNumber2: val?.phoneNumber2,
      whatsAppNumber: val?.whatsAppNumber,
      workingHours: val?.workingHours,
      about: val?.about,
      action: "",
    };
  });
  const columns = [
    { field: "Sr_No", headerName: "Sr No", flex: 1, minWidth: 60 },
    // {
    //   field: "action", headerName: "Action",
    //   flex: 2,
    //   minWidth: 250,
    //   renderCell: (params: any) => <StatusButton params={params?.row} branchlist={props?.branchlist} />,
    // },
    { field: "branchName", headerName: "Branch Name", flex: 2, minWidth: 130, renderCell: renderCellWithTooltip },
    { field: "branchNo", headerName: "Branch No", flex: 2, minWidth: 100, renderCell: renderCellWithTooltip },
    { field: "businessName", headerName: "Business Name", flex: 2, minWidth: 160, renderCell: renderCellWithTooltip },
    { field: "businessAccountNo", headerName: "Business Account No", flex: 2, minWidth: 160, renderCell: renderCellWithTooltip },
    { field: "email", headerName: "Email", flex: 2, minWidth: 170, renderCell: renderCellWithTooltip },
    { field: "createdBy", headerName: "CreatedBy", flex: 2, minWidth: 170, renderCell: renderCellWithTooltip },
    { field: "updatedBy", headerName: "UpdatedBy", flex: 2, minWidth: 170, renderCell: renderCellWithTooltip },
    { field: "city", headerName: "City", flex: 2, minWidth: 150, renderCell: renderCellWithTooltip },
    { field: "businessCountry", headerName: "Business Country", flex: 2, minWidth: 160, renderCell: renderCellWithTooltip },
    { field: "facebook", headerName: "Facebook", flex: 2, minWidth: 170, renderCell: renderCellWithTooltip },
    { field: "instagram", headerName: "Instagram", flex: 2, minWidth: 170, renderCell: renderCellWithTooltip },
    { field: "website", headerName: "Website", flex: 2, minWidth: 170, renderCell: renderCellWithTooltip },
    { field: "postalCode", headerName: "Postal Code", flex: 2, minWidth: 150, renderCell: renderCellWithTooltip },
    { field: "landmark", headerName: "Landmark", flex: 2, minWidth: 150, renderCell: renderCellWithTooltip },
    { field: "address1", headerName: "Address 1", flex: 2, minWidth: 150, renderCell: renderCellWithTooltip },
    { field: "address2", headerName: "Address 2", flex: 2, minWidth: 150, renderCell: renderCellWithTooltip },
    { field: "phoneNumber1", headerName: "Phone Number 1", flex: 2, minWidth: 150, renderCell: renderCellWithTooltip },
    { field: "phoneNumber2", headerName: "Phone Number 2", flex: 2, minWidth: 150, renderCell: renderCellWithTooltip },
    { field: "whatsAppNumber", headerName: "WhatsApp Number", flex: 2, minWidth: 150, renderCell: renderCellWithTooltip },
    {
      field: "about", headerName: "About", flex: 2, minWidth: 150,
      renderCell: (params: any) => {
        const aboutHtml: any = params?.row?.about || '';
        return <div dangerouslySetInnerHTML={{ __html: aboutHtml }} />;
      }
    },
  ];

  return (
    <div style={{ width: "100%" }}>
      <DataGrid
        columns={columns}
        rows={row}
        autoHeight
        rowHeight={48}
        pageSize={100}
        headerHeight={48}
        checkboxSelection
        disableSelectionOnClick
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        onSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
        getRowId={(row) => row?.id}
      />
    </div>
  );
}
