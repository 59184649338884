import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import pm from "../../../newStyle.module.scss";
import st from "../../../style.module.scss";
import { useForm } from "react-hook-form";
import { Tab, Nav, Row, Col, Accordion } from "react-bootstrap";
import basic from "../../../images/basicInfoTab.svg";
import Checkbox from "../../../components/Forms/Checkbox";
import MultiSelect from "../../../components/MultipleSelect/MultipleSelect";
import uploadFile from "../../../images/iconUpload.svg";
import uploadIcon from "../../../images/uploadIcon.svg";
import SuccessPasswordModal from "../../../components/Modals/SuccessPasswordModal";
import OnOffSwitch from "../../../components/Forms/OnOffSwitch";
import { IoIosCloseCircle } from "react-icons/io";
import crossIcon from '../../../assest/crossRed.svg'
import { httpRequest } from "../../../Apis/commonApis";
import { useLocation, useNavigate } from "react-router-dom";

const AddServiceCategory = (props: any) => {
    const { register, handleSubmit, formState: { errors }, setValue, reset, watch } = useForm();
    const [editData, setEditData] = useState<any>(null);

    const location = useLocation()
    const navigate = useNavigate();
    const [apiError, setApiError] = useState("");
    const [errorTrig, setErrorTrig] = useState<any>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [serviceTagList, setServiceTagList] = useState<any[]>([""])
    const [categoryData, setCategoryData] = useState<any>([])
    const [selectedBusinessCat, setSelectedBusinessCat] = useState<any>([]);

    const [message, setMessage] = useState<String>("");
    const [show, setShow] = useState<boolean>(false);
    const handleClose = () => {
        setMessage("")
        setShow(false)
    }

    useEffect(() => {
        if (location?.state?._id) {
            getServiceCatagory();
        }

    }, [location?.state?._id]);

    const getServiceCatagory = async () => {
        const res = await httpRequest(`getServiceCategory?_id=${location?.state?._id}&type=list`, "get", null, "json");
        if (res.status === true) {
            setEditData(res?.data?.[0]);
        }
    };

    useEffect(() => {
        if (editData){
            setValue("categoryName", editData?.serviceCategoryName);
            setSelectedBusinessCat(editData?.businessCategoryId?.map((cat:any) => ({label:cat?.BusinessCategoryName, value:cat?._id})))
        }
    }, [editData])

    const serviceTaglist = async () => {
        const res = await httpRequest(`get_ServiceTag`, "get", null, "auth");
        if (res.status === true) {
            // let servTags = res?.data?.map((item: any) => item?.tags)      
            let servTags = res?.data

            setServiceTagList(servTags);
        }
    };

    const getCatagory = async () => {
        const res = await httpRequest("get_Catagory", "get", null, null);
        if (res.status) {
            // setCatagoryData(res?.data)
            const list = res?.data
            const categoryOptions = list?.[0]?.label ? list : list?.map((tag: any) => {
                return { value: tag?._id, label: tag?.BusinessCategoryName };
            });
            setCategoryData(categoryOptions)
        }

    }

    useEffect(() => {
        serviceTaglist()
        getCatagory()
    }, []);

    const submitHandler = async (data: any) => {
        if (selectedBusinessCat?.length === 0) {
            setErrorTrig(true)
            return
        }
        if (
            data.categoryName !== ""
        ) {
            const finalData = {
                serviceCategoryName: data?.categoryName,
                businessCategoryId: selectedBusinessCat?.map((item: any) => item?.value)
            };

            const path = editData ? `updateServiceCategory/${location?.state?._id}` : "createServiceCategory"
            const method = !editData ? "POST" : "PATCH";
            setLoading(true)
            const res = await httpRequest(path, method, finalData, "json");
            if (res.status) {
                setErrorTrig(false);
                setMessage(res.message);
                setShow(true);
                setApiError("");
                setTimeout(() => {
                    navigate(-1);
                }, 2000)
                reset();
            } else {
                setApiError(res.message);
            }


            setLoading(false)
        }
    };

    console.log(loading , "loading");
    

    return (
        <>
            <SuccessPasswordModal handleClose={handleClose} show={show} message={message} />

            <section className={`${pm.pageWrapper}`} style={{ overflowY: "auto", height: "100%", paddingBottom: "80px" }}>
                <div className={`${pm.pageTitle}`}>
                    <div className={`${pm.titleInfo}`}>
                        {editData ? `${editData?.serviceCategoryName || ""}` : "Add Service Category"}
                    </div>
                    <div className={`${pm.rightInfo}`}></div>
                </div>

                <div className={`${pm.tabbingBox}`}>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="tabNew1">
                        <Nav variant="pills">
                            <Nav.Item>
                                <Nav.Link eventKey="tabNew1">
                                    <img src={basic} alt="info" className={`${pm.default}`} />
                                    Details
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content>
                            <Tab.Pane eventKey="tabNew1">

                                <Row>
                                    <Col xl={3} lg={4} md={6}>
                                        <div className={cx.price_categorySelect}>
                                            <div className={pm.formBox}>
                                                <label className="form-label">Service category name</label>
                                                <input type="text" className="form-control" placeholder="Service category name" {...register("categoryName", { required: true })} />


                                                {errors?.categoryName?.type === "required" && (
                                                    <p className={"errorMessage"}>
                                                        This field is required
                                                    </p>

                                                )}
                                                {apiError !== "" && (
                                                    <p className={"errorMessage"}>
                                                        {apiError}
                                                    </p>

                                                )}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xl={3} lg={4} md={6}>
                                        <div className={cx.price_categorySelect}>
                                            <div className={pm.formBox}>
                                                <label className="form-label">Business Category</label>
                                                <MultiSelect

                                                    value={selectedBusinessCat}
                                                    onChange={(data: any) => {
                                                        
                                                        setSelectedBusinessCat(data)
                                                    }}
                                                    list={categoryData}
                                                />
                                                {/* {catError && categoryTag.length === 0 && (
                                                        <p className={"errorMessage"}>
                                                            This field is required
                                                        </p>
                                                    )} */}
                                                {errorTrig && selectedBusinessCat?.length === 0 && (
                                                    <p className={"errorMessage"}>
                                                        This field is required
                                                    </p>

                                                )}
                                            </div>
                                        </div>
                                    </Col>

                                    {/* <Col xl={3} lg={4} md={6}>
                                            <div className={cx.price_categorySelect}>
                                                <div className={pm.formBox}>
                                                    <label className="form-label">Service Tags</label>
                                                    <MultiSelect
                                                        defaultVal={location?.state?.serviceCategoryTag}
                                                        value={categoryTag}
                                                        onChange={selectHandler}
                                                        list={serviceTags?.map((item: any) => ({ label: item?.tags, value: item?._id }))}
                                                        disabled={serviceTags?.length === 0}
                                                    />
                                                    {catError && categoryTag.length === 0 && (
                                                        <p className={"errorMessage"}>
                                                            This field is required
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                        </Col> */}
                                </Row>
                                {/* <Row>
                                        <Col xl={6} lg={6} md={6}>
                                            <div className={cx.price_categorySelect}>
                                                <div className={pm.formBox}>
                                                    <label className="form-label">Service Category Details</label>
                                                    <textarea
                                                        className="form-control"
                                                        placeholder="Service Category Details"
                                                        defaultValue={location?.state?.serviceCategoryDetails}
                                                        style={{ minHeight: "100px" }}
                                                        {...register("categoryDetail")}


                                                    ></textarea>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row> */}
                                <Row>
                                    <Col md={12}>
                                        <div className={`${pm.formBox}`}>
                                            <div className={`${pm.btnsGroup}`}>
                                                <button
                                                    className={`btn ${pm.btn1}`}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        navigate(-1);
                                                    }}
                                                >
                                                    Back
                                                </button>
                                                <button
                                                    className={`btn ${pm.btn4}`}
                                                    onClick={handleSubmit(submitHandler)}
                                                    type="submit"
                                                >
                                                    {loading ? (
                                                        <div
                                                            className="spinner-border spinner-border-sm text-light"
                                                            role="status"
                                                        ></div>
                                                    ) : (
                                                        editData ? "Update" : 'Save'
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
            </section>
        </>
    );
};

export default AddServiceCategory;
