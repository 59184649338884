import React, { useCallback, useState, useEffect } from "react";
import st from "../../../style.module.scss";
import pm from "./Tablestyle.module.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import cx from "./BranchAndLocation.module.scss";
import tb from "../../../datatable.module.scss";
import { Card, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import { AiFillDelete, AiOutlinePlusCircle } from "react-icons/ai";
import { FiUpload } from "react-icons/fi";

// --------- Images --------- //
import DemoInfo from "../../../components/DemoInfo/DemoInfo";

import SettingsMenu from "../../../components/Sidebar/SettingsMenu";
import TableDataGrid from "./DataGrid";
import DeletePopup from "../../../components/Modals/DeletePopup";
import AmenitiesM from "../../../components/Modals/Amenities";
import BusinessMenu from "../../../components/Sidebar/BusinessMenu";
import CustomersMenu from "../../../components/Sidebar/CustomersMenu";
import WebpageMenu from "../../../components/Sidebar/WebpageMenu";
import ImageManagementM from "../../../components/Modals/ImageManagementModal";
import { httpRequest } from "../../../Apis/commonApis";
import { FadeLoader } from "react-spinners";
import Select from "react-select";

export default function BussinessManagements() {
    const [show, setShow] = useState(false);
    const [businessINfo, setsetbusinessInfo] = useState<any>([]);
    const [businessCategory, setBusinessCategory] = useState<any>([]);
    const [selectedCategories, setSelectedCategories] = useState<any>([]);
    const [selectedB2CAct, setSelectedB2CAct] = useState<any>(null);
    const [selectedPublish, setSelectedPublish] = useState<any>(null);



    const [selectedStatus, setSelectedStatus] = useState<any>(null);
    const [selectedVerification, setSelectedVerification] = useState<any>(null);



    let [loading, setLoading] = useState(true);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);



    //   const filterCategory = businessCategory?.filter((cat: any) => cat?._id === ))
    //   const filterCategory = businessCategory?.filter((cat: any) => cat?._id === webpage?.businessCategoryId?._id ? true : !newWeb?.some((item: any) => item?.businessCategoryId?._id === cat?._id))


    //   console.log(filterCategory, "kkkk")

    const [filters, setFilters] = useState<any>({
        featured: false,
        trending: false,
        // verified: false,
        // active: false,
        // inactive: false,
        // mobileVerified: false,
        // emailVerified: false,
    });


    const statusOptions = [
        { value: "", label: "Select" }, 
        { value: "isActive", label: "Active" },
        { value: "inactive", label: "Inactive" },
       
    ];

    const verificationOptions = [
        { value: "", label: "Select" }, 
        { value: "mobileVerified", label: "Mobile Verified" },
        { value: "NomobileVerified", label: "Not Mobile Verified" },
        { value: "emailVerified", label: "Email Verified" },
        { value: "NoemailVerified", label: "Not Email Verified" },
        
    
    ];

    const b2cActOptions = [
        { value: "", label: "Select" },
        { value: "AccessStatus", label: "Active" },
        { value: "InAccessStatus", label: "Inactive" },
    ];

    const publishOptions = [
        { value: "", label: "Select" },
        { value: "isPublished", label: "True" },
        { value: "unpublished", label: "False" },
    ];


    const handleB2CActChange = (selectedOption: any) => {
        setSelectedB2CAct(selectedOption);
    };

    const handlePublishChange = (selectedOption: any) => {
        setSelectedPublish(selectedOption);
    };


    const handleStatusChange = (selectedOption: any) => {
        setSelectedStatus(selectedOption);
    };

    const handleVerificationChange = (selectedOption: any) => {
        setSelectedVerification(selectedOption);
    };


    const categoryOptions = businessCategory?.map((item: any) => ({
        value: item._id,
        label: item.BusinessCategoryName,
    }));


    useEffect(() => {
        getBusinessInfo();
        getbusinessCatagory();
    }, []);
    const getBusinessInfo = async () => {
        const res = await httpRequest("get_BusinessInfo?type=list", "get", null, "withoutToken");
        if (res?.status == true) {
            setLoading(false)
            setsetbusinessInfo(res?.data)
        }
    };
    const getbusinessCatagory = async () => {
        const res: any = await httpRequest("get_Catagory", "get", null, "withoutToken");
        if (res?.status == true) {
            setBusinessCategory(res?.data)
        } else {
            setLoading(false)
        }
    };


    const handleFilterClick = (filter: any) => {
        setFilters((prevFilters: any) => ({
            ...prevFilters,
            [filter]: !prevFilters[filter],
        }));
    };

    const handleCategoryChange = (selectedOptions: any) => {
        const selectedValues = selectedOptions ? selectedOptions.map((option: any) => option.value) : [];
        setSelectedCategories(selectedOptions);
    };



    const filteredBusinessInfo = businessINfo?.filter((item: any) => {


        // console.log('Item:', item);
        // console.log('Selected Categories:', selectedCategories);

        const filterCatCheck = selectedCategories.length > 0
            ? selectedCategories.some((selectedCategory: any) =>
                item?.businessCatagoryId?.some((cat: any) => cat?._id === selectedCategory?.value)
            )
            : true;


            // console.log('Filter Cat Check:', filterCatCheck);
        if (!filterCatCheck) return false;
        if (filters.featured && !item.featured) return false;
        if (filters.trending && !item.trending) return false;
        // if (filters.verified && !item.verified) return false;
        if (selectedStatus?.value === "isActive" && !item.isActive) return false;
        if (selectedStatus?.value === "inactive" && item.isActive) return false;
        if (selectedVerification?.value === "mobileVerified" && item.mobileVerified) return false;
        if (selectedVerification?.value === "NomobileVerified" && !item.mobileVerified) return false;
        if (selectedVerification?.value === "emailVerified" && item.emailVerified) return false;
        if (selectedVerification?.value === "NoemailVerified" && !item.emailVerified) return false;
        if (selectedB2CAct?.value === "AccessStatus" && !item.AccessStatus) return false;
        if (selectedB2CAct?.value === "InAccessStatus" && item.AccessStatus) return false;
        if (selectedPublish?.value === "isPublished" && !item.isPublished) return false;
        if (selectedPublish?.value === "unpublished" && item.isPublished) return false;
        return true;
    });

    return (
        <>
            <section className={`${st.pageWrapper} ${st.pageWrapperOpen}`}>
                <WebpageMenu />
                <div className={`${st.pageTitle}`}>
                    <Row>
                        <Col md={6} lg={6}>
                            <div className={`${st.titleInfo}`}>
                                <div className={`${st.titleInfoBody}`}>
                                    <h5>Webpage setup</h5>
                                    <p>Bussiness Management</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={6} lg={6}>
                            <DemoInfo />
                        </Col>
                    </Row>
                </div>

                <div className={`${st.pageWrapperInside}`}>
                    <Card>
                        <Card.Body>
                            <Row className={`${tb.tableAction} align-items-end`}>
                                <Col lg={4} md={6}>
                                    <div className={`${pm.formBox}`}>
                                        <label className="form-label">Category</label>
                                        <Select
                                            isMulti
                                            name="categories"
                                            options={categoryOptions}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            onChange={handleCategoryChange}
                                            value={selectedCategories}
                                        />
                                    </div>
                                </Col>
                                <Col lg={2} md={6}>
                                    <div className={`${pm.formBox}`}>
                                        <label className="form-label">B2C Act</label>
                                        <Select
                                            name="status"
                                            options={statusOptions}
                                            className="basic-single-select"
                                            classNamePrefix="select"
                                            onChange={handleStatusChange}
                                            value={selectedStatus}
                                        />
                                    </div>
                                </Col>

                                <Col lg={2} md={6}>
                                    <div className={`${pm.formBox}`}>
                                        <label className="form-label">Verification</label>
                                        <Select
                                            name="verification"
                                            options={verificationOptions}
                                            className="basic-single-select"
                                            classNamePrefix="select"
                                            onChange={handleVerificationChange}
                                            value={selectedVerification}
                                        />
                                    </div>
                                </Col>
                                <Col lg={2} md={6}>
                                    <div className={`${pm.formBox}`}>
                                        <label className="form-label">Access Status</label>
                                        <Select
                                            name="b2cAct"
                                            options={b2cActOptions}
                                            className="basic-single-select"
                                            classNamePrefix="select"
                                            onChange={handleB2CActChange}
                                            value={selectedB2CAct}
                                        />
                                    </div>
                                </Col>
                                <Col lg={2} md={6}>
                                    <div className={`${pm.formBox}`}>
                                        <label className="form-label">Publish</label>
                                        <Select
                                            name="publish"
                                            options={publishOptions}
                                            className="basic-single-select"
                                            classNamePrefix="select"
                                            onChange={handlePublishChange}
                                            value={selectedPublish}
                                        />
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className="d-flex" style={{ marginBottom: '15px' }}>
                                        <button
                                            className={`btn ${tb.upload} ${filters.featured ? tb.active : ''}`} 
                                            onClick={() => handleFilterClick("featured")}
                                            style={{ backgroundColor: filters.featured ? "#36d7b7" : "", height:'38px' }}
                                        >
                                            Featured
                                        </button>
                                        <button
                                            className={`btn ${tb.upload} ${filters.trending ? tb.active : ''}`}
                                            onClick={() => handleFilterClick("trending")}
                                            style={{ backgroundColor: filters.trending ? "#36d7b7" : "", height:'38px' }}
                                        >
                                            Trending
                                        </button>

                                        {/* <button
                                            className={`btn ${tb.upload} ${filters.active ? tb.active : ''}`}
                                            onClick={() => handleFilterClick("active")}
                                            style={{ backgroundColor: filters.active ? "#36d7b7" : "" }}
                                        >
                                            Active
                                        </button>
                                        <button
                                            className={`btn ${tb.upload} ${filters.inactive ? tb.active : ''}`}
                                            onClick={() => handleFilterClick("inactive")}
                                            style={{ backgroundColor: filters.inactive ? "#36d7b7" : "" }}
                                        >
                                            Inactive
                                        </button>
                                        <button
                                            className={`btn ${tb.upload} ${filters.mobileVerified ? tb.active : ''}`}
                                            onClick={() => handleFilterClick("mobileVerified")}
                                            style={{ backgroundColor: filters.mobileVerified ? "#36d7b7" : "" }}
                                        >
                                            MobileVerified
                                        </button>
                                        <button
                                            className={`btn ${tb.upload} ${filters.emailVerified ? tb.active : ''}`}
                                            onClick={() => handleFilterClick("emailVerified")}
                                            style={{ backgroundColor: filters.emailVerified ? "#36d7b7" : "" }}
                                        >
                                            EmailVerified
                                        </button> */}
                                    </div>
                                </Col>



                            </Row>
                            <div className={`${tb.dataTable}`}>
                                {loading === true ? (
                                    <FadeLoader loading={loading} margin={2} width={5} color="#36d7b7" style={{ position: "absolute", top: "50%", left: "50%" }} />
                                ) : (<TableDataGrid businessUserData={filteredBusinessInfo} getBusinessInfo={getBusinessInfo} />)}
                                {/* ) : (<TableDataGrid businessUserData={businessINfo} getbusinessUser={getBusinessInfo} />)} */}
                                {/* <TableDataGrid   businessUserData={businessUser} getbusinessUser={getbusinessUser}  /> */}
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </section>

            <ImageManagementM show={show} handleClose={handleClose} />
        </>
    );
}
